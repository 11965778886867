import { ActionType, ActiveTabActionTypes } from "../action-types/index";
import { ActionInterface, ActiveTabAction } from "../actions-interface/index";

const initialstate = "listings";

const ActiveTabReducer = (
  state: string = initialstate,
  action: ActiveTabAction
) => {
  switch (action.type) {
    case ActiveTabActionTypes.SET_ACTIVE_TAB:
      return action.payload;
    default:
      return state;
  }

  return state;
};
export default ActiveTabReducer;
