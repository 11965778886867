import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Form } from "../../atomic_components/types";
import { useAppSelector } from "../../state/hooks";

interface SaveDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  payloadForm: Form;
}

const FormsConfirmSubmissionDialog: React.FC<SaveDialogProps> = ({
  open,
  onClose,
  onSubmit,
  payloadForm,
}) => {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Are you sure you want to submit {payloadForm.form_name}?
      </DialogTitle>
      <DialogContent>
        *Note - This form will be submitted in the name of{" "}
        <b>{userDetailsFromStore.user_details.name}</b> having id{" "}
        <b>{userDetailsFromStore.user_details.userId}</b>
        <div className=""></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormsConfirmSubmissionDialog;
