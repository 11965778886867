import { SearchParams } from "../../atomic_components/types";
import { SearchTextActionTypes } from "../action-types/index";
import { SearchTextAction } from "../actions-interface/index";

const initialState = "";

const listingSearchParametersReducer = (
  state: string = initialState,
  action: SearchTextAction
) => {
  switch (action.type) {
    case SearchTextActionTypes.SET_SEARCH_TEXT:
      console.log("inside search text action reducer")
      return action.payload;
    default:
      return state;
  }  
};
export default listingSearchParametersReducer;
