import React, { useEffect, useState } from "react";

import "./CSS/VendorsHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import VendorsAll from "./VendorsAll";
import VendorsAdd from "./VendorsAdd";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function VendorHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(ActiveTab__Action("vendors"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/vendors/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div>
      <div className="nav-container">
        <nav className="navbar-vendors">
          <ul className="navbar-menu">
            <li
              className={`navbar-item ${
                selectedItem === "all" ? "active" : ""
              }`}
              onClick={() => handleItemClick("all")}
            >
              All Vendors
            </li>
            {/* <li
              className={`navbar-item ${
                selectedItem === "add" ? "active" : ""
              }`}
              onClick={() => handleItemClick("add")}
            >
              Add New Vendor
            </li> */}
          </ul>
        </nav>
      </div>

      {selectedItem == "all" && <VendorsAll />}
      {/* {selectedItem == "add" && <VendorsAdd />} */}
    </div>
  );
}

export default VendorHome;
