import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import "./CSS/CataloguesSearchBar.css";
import { useAppSelector } from "../../state/hooks";
import { SearchParams } from "../../atomic_components/types";

type Props = {
  onSearch: (query: string) => void;
};

function CataloguesSearchBar({ onSearch }: Props) {
  // const searchTextFromStore = useAppSelector((state) => state.search_text);

  // const [searchQuery, setSearchQuery] = useState(searchTextFromStore);
  const [searchQuery, setSearchQuery] = useState("");

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log(searchQuery);
      onSearch(searchQuery);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length == 0) onSearch(e.target.value);
    setSearchQuery(e.target.value);
  };

  return (
    <div className="catalogues-search-bar">
      <input
        type="text"
        placeholder="Search catalogue name, id "
        value={searchQuery}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
    </div>
  );
}

export default CataloguesSearchBar;
