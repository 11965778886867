import React from "react";
import "./CSS/ColorCard.css"; // Import your CSS file for styling

interface ColorCardProps {
  color: "red" | "orange" | "green";
}

const ColorCard: React.FC<ColorCardProps> = ({ color }) => {
  const getColorExplanation = (): string => {
    switch (color) {
      case "red":
        return "Expired";
      case "orange":
        return "Expiring in the next 5 days";
      case "green":
        return "Have time";
      default:
        return "";
    }
  };

  return (
    <div className="color-card mb-1 mr-3">
      <div className={`color-box ${color}`}></div>
      <div className="color-text">{getColorExplanation()}</div>
    </div>
  );
};

export default ColorCard;
