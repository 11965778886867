import React, { useState, useEffect } from "react";
import "./CSS/ListingsView.css";
import {
  ImsListing,
  Listing,
  ListingPage,
} from "../../atomic_components/types";
import ListingDetails from "./ListingDetails";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

const ListingCard: React.FC<{
  listing: ImsListing;
  category: string;
  handleCardClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    listing: ImsListing
  ) => void;
}> = ({ listing, category, handleCardClick }) => {
  const [localListing, setLocalListing] = useState<ImsListing>(listing);
  const handleImageError = (imageKey: string) => {
    const updatedListing = { ...localListing };
    updatedListing[imageKey] = "http://tech.intellozene.com/Media/na.png";
    setLocalListing(updatedListing);
  };

  return (
    <a href={`/listings/${category}/${listing.item_id}`}>
      <div
        className="listings-view-card"
        onClick={(e) => handleCardClick(e, localListing)}
      >
        <div className="listings-img-container">
          <img
            src={localListing.img_main}
            alt="Product Image"
            onError={() => handleImageError("img_wdim")}
          />
        </div>

        <div className="listings-view-card-title">
          <h3
            className={`sku-id-title ${
              localListing.available_quantity === 0
                ? "not-available"
                : "available"
            }`}
          >
            {localListing.item_id}
          </h3>
        </div>
        <div>
          <p
            className={`listings-view-card-bold-text ${
              localListing.available_quantity === 0 ? "red" : "green"
            }`}
          >
            Available quantity : {localListing.available_quantity}
          </p>
        </div>
        <div>
          <b>Product Description : </b>
          {localListing.item_description}
        </div>
      </div>
    </a>
  );
};

type ListingsViewProps = {
  listings: ImsListing[];
};

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

// export default function ListingsView(data: ListingPage) {
export default function ListingsView({ listings }: ListingsViewProps) {
  // console.log(listings);
  const history = useHistory();
  const { category } = useParams<RouteParams>();

  // useEffect(() => {
  //   // Add event listener for the 'popstate' event
  //   window.addEventListener("popstate", (event) => {
  //     // Check if the state includes the property skuDetailsOpen
  //     // console.log("inside  popstate event");
  //     // console.log(event.state);
  //     // if (event.state && event.state.skuDetailsOpen) {
  //     handleClose(); // Close the overlay
  //     // }
  //   });

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("popstate", handleClose);
  //   };
  // }, []);

  const handleCardClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    listing: ImsListing
  ) => {
    // Use pushState to add a new history state without navigating
    // window.history.pushState({ skuDetailsOpen: true }, ""); // The second parameter is the title (can be empty)
    // history.push(`/listings/${category}/${listing.sku_id}`);
    // const url = `/listings/${category}/${listing.sku_id}`;
    // const isNewTab = event.metaKey || event.ctrlKey;
    // if (isNewTab) {
    //   const win = window.open(url, "_blank");
    //   // const win = window.open(url);
    //   if (win) {
    //     win.focus(); // Focus on the new tab if it opened successfully
    //   } else {
    //     console.error("Popup blocked. Please allow popups for this website.");
    //   }
    // } else {
    //   history.push(url);
    // }
  };

  return (
    <div className="all-listings-only-card-container">
      {listings &&
        listings.length > 0 &&
        listings.map((listing, index) => (
          <ListingCard
            key={index}
            listing={listing}
            category={category}
            handleCardClick={handleCardClick}
          />
        ))}
    </div>
  );
}
