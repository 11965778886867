import React, { useState, useEffect } from "react";
import "./CSS/CatalogueItemsView.css";
import { CatalogueItem } from "../../atomic_components/types";

import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import DateTimeUtils from "../../utils/DateTimeUtils";

const ListingCard: React.FC<{
  catalogueItem: CatalogueItem;
  handleCardClick: (listing: CatalogueItem) => void;
  selectedImageKey: string;
}> = ({ catalogueItem, handleCardClick, selectedImageKey }) => (
  <div
    className="listings-view-card vendor-listing-card"
    onClick={() => handleCardClick(catalogueItem)}
  >
    <div className="listings-img-container">
      {selectedImageKey == "img_wdim" && (
        <img src={catalogueItem.listing.img_wdim} alt="Product Image" />
      )}
      {selectedImageKey == "img_hd" && (
        <img src={catalogueItem.listing.img_hd} alt="Product Image" />
      )}
      {selectedImageKey == "img_link1" && (
        <img src={catalogueItem.listing.img_link1} alt="Product Image" />
      )}
      {selectedImageKey == "img_link2" && (
        <img src={catalogueItem.listing.img_link2} alt="Product Image" />
      )}
      {selectedImageKey == "img_white" && (
        <img src={catalogueItem.listing.img_white} alt="Product Image" />
      )}
    </div>

    <div className="listings-view-card-title">
      <h3
        className={`sku-id-title ${
          catalogueItem.listing.available_quantity === 0
            ? "not-available"
            : "available"
        }`}
      >
        {catalogueItem.listing.inventory_sku_id}
      </h3>
    </div>
    <p>OPS Tag: {catalogueItem.listing.ops_tag}</p>

    <p>Available Quantity: {catalogueItem.listing.available_quantity}</p>
    <p className="catalogue-listings-moq">M.O.Q: {catalogueItem.moq}</p>
    <p className="catalogue-listings-moq">
      Unit Price: {catalogueItem.listing.bulk_price}
    </p>

    {/* <div className="cost-price-container">
      <p>Cost Price : </p>
      <input
        type="text"
        name="vendor_cost_price"
        value={catalogueItem.cost_price}
      />
    </div> */}

    <p>
      {`L.W.H:   ${catalogueItem.listing.length}*${catalogueItem.listing.width}*${catalogueItem.listing.height}`}
    </p>
  </div>
);

type CatalogViewProps = {
  catalogueItems: CatalogueItem[];
  onCardClick: (selectedListing: CatalogueItem) => void;
  selectedImageKey: string;
};

export default function CatalogueItemsView({
  catalogueItems,
  onCardClick,
  selectedImageKey,
}: CatalogViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const handleCardClick = async (listing: CatalogueItem) => {
    onCardClick(listing);
  };

  return (
    <div>
      <div className="vendor-listings-card-container">
        {catalogueItems && catalogueItems.length > 0 ? (
          catalogueItems.map((catalogueItem, index) => (
            <ListingCard
              key={index}
              catalogueItem={catalogueItem}
              handleCardClick={handleCardClick}
              selectedImageKey={selectedImageKey}
            />
          ))
        ) : (
          <div className="no-items-view">"Nothing to Display"</div>
        )}
      </div>
    </div>
  );
}
