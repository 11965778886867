import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/PurchaseOrdersOpen.css";
import PurchaseOrdersSearchBar from "./PurchaseOrdersSearchBar";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import { PurchaseOrder, SearchParams } from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { Prev } from "react-bootstrap/esm/PageItem";
import ColorCard from "./ColorCard";

function PurchaseOrdersOpen() {
  const history = useHistory();
  const location = useLocation();
  const purchaseOrdersNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [allPurchaseOrders, setAllPurchaseOrders] = useState<PurchaseOrder[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const getPurchaseOrdersPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await purchaseOrdersNetworkUtil.getPurchaseOrdersPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          28
        );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllPurchaseOrders((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching vendor listings by vendor id :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllPurchaseOrders([]);

    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getPurchaseOrdersPaginated(query, 1, true);
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getPurchaseOrdersPaginated("", searchParams.current_page_num + 1, false);
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  useEffect(() => {
    getPurchaseOrdersPaginated("", 1, false);
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const navigateToPurchaseOrderDetailsPage = (po: PurchaseOrder) => {
    // let url = "";
    // if (po.status == "PENDING" || po.status == "MARKED_MODIFICATION") {
    //   url = `/purchase-orders/add/addItems/${po.po_id}`;
    // } else {
    //   url = `/purchase-orders/published/${po.po_id}`;
    // }
    // history.push(url);
    // const win = window.open(url, "_blank");
    // if (win) {
    //   win.focus(); // Focus on the new tab if it opened successfully
    // } else {
    //   console.error("Popup blocked. Please allow popups for this website.");
    // }
  };

  const getPurchaseOrderStatus = (poStatus: string) => {
    if (poStatus == "PENDING") return "Pending";
    else if (poStatus == "PENDING_PUBLISHED") return "Published";
    else if (poStatus == "MARKED_MODIFICATION")
      return "Marked For Modification";
    else if (poStatus == "MARKED_RECEIVING") return "Marked for Receiving";
    else if (poStatus == "MARKED_PARTIALLY_RECEIVED")
      return "Partially Received";
    else if (poStatus == "MARKED_COMPLETED") return "Completed";
    else if (poStatus == "MARKED_CANCELLED") return "Cancelled";
    return "NA";
  };

  return (
    <div className="purchase-orders-container">
      <div className="purchase-orders-search-bar-container">
        <div className="colors-overview-container">
          <ColorCard color="red" />
          <ColorCard color="orange" />
          {/* <ColorCard color="green" /> */}
        </div>
        <PurchaseOrdersSearchBar onSearch={handleSearch} />
      </div>
      {allPurchaseOrders.length > 0 ? (
        <div className="purchase-orders-table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>PO Id</th>
                <th>Vendor Id</th>
                <th>Po Name</th>
                <th>PO status</th>
                <th>Sku Count</th>
                <th>Total Quantity</th>
                <th>Date Created</th>
                <th>Date Published</th>
                <th>Expiry Date</th>
                <th>Last Updated</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {allPurchaseOrders.map((po, index) => {
                const dateStatus = DateTimeUtils.getDateStatus(
                  po.expected_date
                );
                return (
                  <tr
                    className="purchase-orders-search-bar-row"
                    key={index}
                    onClick={() => navigateToPurchaseOrderDetailsPage(po)}
                  >
                    <td className="po-id-link">
                      <a
                        href={
                          po.status == "PENDING" ||
                          po.status == "MARKED_MODIFICATION"
                            ? `/purchase-orders/add/addItems/${po.po_id}`
                            : `/purchase-orders/published/${po.po_id}`
                        }
                      >
                        {po.po_id}
                      </a>
                    </td>
                    <td className="po-id-link">
                      <a href={`/vendors/all/${po.vendor_id}`}>
                        {po.vendor_id}
                      </a>
                    </td>
                    <td>{po.po_name}</td>
                    <td>{getPurchaseOrderStatus(po.status)}</td>
                    <td>{po.sku_count}</td>
                    <td>{po.total_quantity}</td>
                    <td>{DateTimeUtils.formatDateTime(po.created_at)}</td>
                    <td>
                      {po.status == "PENDING"
                        ? ""
                        : DateTimeUtils.formatDateTime(po.date_published)}
                    </td>
                    <td
                      className={
                        `expiry-date ` +
                        (dateStatus < 0
                          ? "red"
                          : dateStatus <= 5
                          ? "orange"
                          : "green")
                      }
                    >
                      {DateTimeUtils.formatDateTime(po.expected_date)}
                    </td>
                    <td>{DateTimeUtils.formatDateTime(po.updated_at)}</td>
                    <td>{po.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count && (
              <div className="load-more-purchase-orders-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-purchase-orders-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No Purchase Orders were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default PurchaseOrdersOpen;
