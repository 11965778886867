import { Bin, ImsListing, Listing } from "../../atomic_components/types";
import { ListingActionTypes, SearchTextActionTypes } from "../action-types/index";
import { ListingAction } from "../actions-interface/index";

const initialState : ImsListing[]= [];

const listingsReducer = (
  state: ImsListing[] = initialState,
  action: ListingAction
) => {
  switch (action.type) {
    case ListingActionTypes.ADD_LISTINGS:
        // console.log(action.payload);
      return [...state, ...action.payload];
    case ListingActionTypes.RESET_LISTINGS:
      return [];
    case ListingActionTypes.REPLACE_LISTINGS:
      return action.payload;
    case ListingActionTypes.UPDATE_LISTING_DETAILS:
      console.log("inside update_listing_details");
      return state.map(l => (l.item_id === action.actionOnSku ? action.listingPayload : l));
    case ListingActionTypes.UPDATE_LISTINGS:
        const binList : Bin[] =  action.binsPayload; 
        const sumOfAvailableQuantities: number = binList.reduce(
          (accumulator, bin) => accumulator + bin.available_quantity,
          0
        );
        const temp =  state.map(item => {
          if (item.sku_id === action.actionOnSku) {
            return { ...item, available_quantity: sumOfAvailableQuantities, bins:binList}; // Set quantity to 10 as an example
          }
          return item;
        });
        console.log(temp);

        return temp;
    default:
      return state;
  }
};
export default listingsReducer;
