import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import "./CSS/DynamicForm.css";
import { Form, FormFields } from "../../atomic_components/types";
import { useHistory } from "react-router-dom";
import FormsNetworkUtil from "./NetworkingUtils/FormsNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import FormsConfirmSubmissionDialog from "./FormsCofirmSubmissionDialog";
import { toast } from "react-toastify";

// interface FormField {
//   type: string;
//   label: string;
//   name: string;
// }

interface DynamicFormProps {
  form: Form;
}

const DynamicForm2: React.FC<DynamicFormProps> = ({ form }) => {
  const history = useHistory();
  const formsNetworkUtil = new FormsNetworkUtil(
    "https://your-api-endpoint.com",
    history
  );
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const [formData, setFormData] = useState<{
    [key: string]: string | number | boolean;
  }>({});

  const [submitClicked, setSubmitClicked] = useState(false);
  const [alreadyFilled, setAlreadyFilled] = useState(false);
  const [nonBooleanFields, setNonBooleanFields] = useState<string[]>([]);
  useState<number>(0);

  useEffect(() => {
    const nonBooleanFieldsLocal = form.form_payload
      .filter((f) => f.type !== "checkbox")
      .map((f) => f.name);
    setNonBooleanFields(nonBooleanFieldsLocal);
    // const initialState: {
    //   [key: string]: string | number | boolean;
    // } = {};
    // form.form_payload.forEach((item) => {
    //   initialState[item.name] = "0";
    // }, {});

    // // console.log(initialState);
    // setFormData(initialState);
    // console.log(nonBooleanFieldsLocal);
    const fetchFormsFilledToday = async () => {
      try {
        const res = await formsNetworkUtil.getFormsDataByFormIdFilledToday(
          userDetailsFromStore.user_details.userId,
          form.id
        );
        console.log(res.data["form_response"]);
        setFormData(res.data["form_response"]);
        setAlreadyFilled(true);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchFormsFilledToday();
  }, [form]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    // Handle checkbox input separately
    if (type === "checkbox") {
      const isChecked = (e.target as HTMLInputElement).checked;
      setFormData({
        ...formData,
        [name]: isChecked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let nonBooleanFieldsSetCount = 0;
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (nonBooleanFields.includes(key)) nonBooleanFieldsSetCount++;
      }
    }

    console.log("handleSubmit", formData);
    // console.log("nonbooleanfieldssetcount : ", nonBooleanFieldsSetCount);
    // setNonBooleanFieldsInResponse(nonBooleanFieldsSetCount);
    if (nonBooleanFieldsSetCount != nonBooleanFields.length)
      toast.error(
        "All fields are mandatory, Please fill all fields and try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
    else setSubmitClicked(true);
  };

  const handleFinalSubmit = () => {
    try {
      const response = formsNetworkUtil.saveFormResponse(
        userDetailsFromStore.user_details.userId,
        form.id,
        formData
      );
      console.log(response);
      toast.success("Successfully updated!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error while submitting form!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
    setSubmitClicked(false);
  };

  const handleDialogClose = () => {
    setSubmitClicked(false);
  };

  const chunkFields = (fields: FormFields[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < fields.length; i += chunkSize) {
      result.push(fields.slice(i, i + chunkSize));
    }
    return result;
  };

  const fieldRows = chunkFields(form.form_payload, 2);

  return (
    <div>
      {form.is_active == 1 && (
        <div>
          <div className="form-container">
            <form className="form" onSubmit={handleSubmit}>
              <h5 className="form-name">{form.form_name}</h5>
              {fieldRows.map((row, rowIndex) => (
                <div className="form-row" key={rowIndex}>
                  {row.map((field, index) => (
                    <div className="form-field" key={index}>
                      <label className="label">{field.label}</label>
                      {field.type === "text" && (
                        <input
                          className="input-field"
                          type="text"
                          name={field.name}
                          onChange={handleChange}
                        />
                      )}
                      {field.type === "number" && (
                        <input
                          className="input-field"
                          type="number"
                          name={field.name}
                          onChange={handleChange}
                          value={formData[field.name] as number | 0}
                        />
                      )}
                      {field.type === "checkbox" && (
                        <input
                          type="checkbox"
                          name={field.name}
                          onChange={handleChange}
                        />
                      )}
                      {/* Add other input types as needed */}
                    </div>
                  ))}
                </div>
              ))}
              {!alreadyFilled ? (
                <button className="button" type="submit">
                  Submit
                </button>
              ) : (
                <p className="filled-message">
                  This form has been filled for today. Updating form is not yet
                  available.
                </p>
              )}
            </form>
          </div>
        </div>
      )}

      {form.is_active == 0 && <div>No active form available</div>}

      <FormsConfirmSubmissionDialog
        open={submitClicked}
        onClose={handleDialogClose}
        onSubmit={handleFinalSubmit}
        payloadForm={form}
        // payloadFormsResponse={formData}
      />
    </div>
  );
};

export default DynamicForm2;
