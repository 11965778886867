import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { Bin, Listing } from "../../../atomic_components/types";

class FormsNetworkUtil {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            history.push("/login");
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getAllFormCategories() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllFormCategories}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllFormCategories");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllFormSubCategories(category: string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllFormCategoriesByName}${category}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllFormSubCategories");
      console.error("Error:", error);
      throw error;
    }
  }

  async getLatestFormBySubCategoryName(category: string, subCategory: string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllFormCategoriesByName}${category}/${subCategory}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllFormSubCategories");
      console.error("Error:", error);
      throw error;
    }
  }

  async saveFormResponse(userId: string, formId: number, form_response: any) {
    try {
      let url = `${requestHeaders.rooturl}${requests.saveFormResponse}${formId}?submitted_by=${userId}`;
      const response = await this.api.post(url, form_response, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from saveFormResponse");
      console.error("Error:", error);
      throw error;
    }
  }

  async getFormsDataByFormIdFilledToday(userId: string, formId: number) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getFormsDataByFormIdFilledToday}${formId}/${userId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getFormsDataByFormIdFilledToday");
      console.error("Error:", error);
      throw error;
    }
  }
}

export default FormsNetworkUtil;
