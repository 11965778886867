import React, { useEffect, useState } from "react";
import {
  ErrorMessageBody,
  ImsListing,
  Listing,
  PacksAndCombos,
  PurchaseOrder,
  Vendor,
  VendorAndCostPrice,
} from "../../atomic_components/types";
import "./CSS/ListingDetails.css";
import Header from "../Header";
import ListingBinGrid from "./ListingBinGrid";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import {
  Listings__Action,
  TopListings__Action,
} from "../../state/action-creators";
import {
  ListingActionTypes,
  TopListingActionTypes,
} from "../../state/action-types";
import { round } from "@floating-ui/utils";
import { Badge, Button, Offcanvas, Spinner, Table } from "react-bootstrap";
import _ from "lodash";
import FilteredDropdown from "../Vendors/FilteredDropdown";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import DateTimeUtils from "../../utils/DateTimeUtils";

interface ListingDetailsProps {
  listing: Listing; // Ensure the prop is correctly defined
}

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  sku: string;
}

// export default function ListingDetails({ listing }: ListingDetailsProps) {
export default function ListingDetails() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const { category, sku } = useParams<RouteParams>();
  const [selectedImage, setSelectedImage] = useState("img_main");

  const [editMode, setEditMode] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [inventoryEditMode, setInventoryEditMode] = useState(false);
  const [parentListing, setParentListing] = useState<ImsListing | null>(null);
  const [listing, setListing] = useState<ImsListing>({
    id: 0,
    item_id: "",
    item_description: "",
    item_category: "",
    img_main: "",
    img_other1: "",
    img_other2: "",
    img_other3: "",
    img_other4: "",
    item_weight: 0,
    gst: 0,
    hsn: "",
    length: 0,
    width: 0,
    height: 0,
    item_price: 0,
    available_quantity: 0,
    created_at: "",
    updated_at: "",
  });
  const [localListing, setLocalListing] = useState<ImsListing>(
    _.cloneDeep(listing)
  );

  useEffect(() => {
    // Make your API call here

    const fetchListingBySkuId = async (sku_id: string) => {
      try {
        const response = await listingNetworkUtil.getListingBySkuId(sku_id);
        console.log(response.data);
        setShowLoadingPage(false);
        setListing(response.data);
        setLocalListing(response.data);
      } catch (error) {
        setShowLoadingPage(false);
        console.error("Error in fetching listing :", error);
      }
    };

    fetchListingBySkuId(sku);
  }, []);

  useEffect(() => {
    if (
      listing.sku_type == "SINGLE" &&
      listing.sku_id != listing.inventory_sku_id
    ) {
      const fetchParentListingBySkuId = async (sku_id: string) => {
        try {
          const response = await listingNetworkUtil.getListingBySkuId(sku_id);
          console.log(response.data);
          setParentListing(response.data);
        } catch (error) {
          console.error("Error in fetching listing :", error);
        }
      };

      fetchParentListingBySkuId(listing.inventory_sku_id);
    }
  }, [listing]);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleImageError = (imageKey: string) => {
    const updatedListing = { ...localListing };
    updatedListing[imageKey] = "http://tech.intellozene.com/Media/na.png";
    setLocalListing(updatedListing);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await listingNetworkUtil.updateListingBySkuId(
        listing.item_id,
        localListing
      );
      dispatch(
        Listings__Action(
          ListingActionTypes.UPDATE_LISTING_DETAILS,
          [],
          listing.item_id,
          [],
          localListing
        )
      );

      console.log(response);
      toast.success("Successfully updated!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setEditMode(false);
      setListing(_.cloneDeep(localListing));
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 422) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        toast.error("Fields cannot be set empty!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unknown error! Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setLocalListing({
      ...localListing,
      [name]: value,
    });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setLocalListing(_.cloneDeep(listing));
  };

  return (
    <div className="overlay">
      <div className="sku-details">
        <Header />
        <div className="main-container">
          <div className="image-container">
            <div className="image-list">
              {[
                "img_main",
                "img_other1",
                "img_other2",
                "img_other3",
                "img_other4",
              ].map((imageKey: string) => (
                <div
                  key={imageKey}
                  className={`image-placeholder ${
                    selectedImage === imageKey ? "active" : ""
                  }`}
                  onClick={() => handleImageClick(imageKey)}
                >
                  <img
                    src={
                      localListing[imageKey]
                        ? localListing[imageKey].toString()
                        : "NA"
                    }
                    alt={`Image ${imageKey}`}
                    onError={() => handleImageError(imageKey)}
                  />
                </div>
              ))}
            </div>

            <div className="zoomed-image">
              <img
                src={localListing[selectedImage].toString()}
                alt={`Image ${selectedImage}`}
                onError={() => handleImageError(selectedImage)}
              />
              <h5 className="mt-2">{selectedImage}</h5>
            </div>
          </div>
          <div className="details-container">
            <div className={`details-card ${editMode ? "editable" : ""}`}>
              <div className="details-first-row">
                <div className="sku-id">{localListing.item_id}</div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="description">
                  <input
                    type="text"
                    name="item_description"
                    value={localListing.item_description || ""}
                    onChange={handleChange}
                    disabled={!editMode}
                  />
                </div>

                <div className="grid-2-cells">
                  <div className="grid-item-details-card dark">
                    <label>Available Quantity</label>
                    <input
                      type="text"
                      name="available_quantity"
                      value={localListing.available_quantity || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Category</label>
                    <input
                      type="text"
                      name="item_category"
                      value={localListing.item_category || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                </div>

                <div className="grid-3-cells">
                  <div className="grid-item-details-card">
                    <label>Item Price</label>
                    <input
                      type="text"
                      name="item_price"
                      value={localListing.item_price || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>GST</label>
                    <input
                      type="text"
                      name="gst"
                      value={localListing.gst || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>HSN code</label>
                    <input
                      type="text"
                      name="hsn"
                      value={localListing.hsn || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                </div>

                <div className="grid-4-cells">
                  <div className="grid-item-details-card">
                    <label>Item weight</label>
                    <input
                      type="text"
                      name="item_weight"
                      value={localListing.item_weight || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Length</label>
                    <input
                      type="text"
                      name="length"
                      value={localListing.length || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>Width</label>
                    <input
                      type="text"
                      name="width"
                      value={localListing.width || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>

                  <div className="grid-item-details-card">
                    <label>Height</label>
                    <input
                      type="text"
                      name="height"
                      value={localListing.height || ""}
                      onChange={handleChange}
                      disabled={!editMode}
                    />
                  </div>
                </div>

                <div className="details-button-container mt-2">
                  <button
                    type="button"
                    onClick={handleEditClick}
                    disabled={editMode}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelClick}
                    disabled={!editMode}
                  >
                    Cancel
                  </button>
                  {editMode && <button type="submit">Save</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}
