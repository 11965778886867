// Layout.tsx
import React, { ReactNode } from "react";
import Header from "../page components/Header";
import Sidebar from "../page components/Sidebar";
interface LayoutProps {
  children: ReactNode;
}

const HomePage: React.FC<LayoutProps> = ({ children }) => {
  // You can include the header and sidebar JSX here
  return (
    <div>
      <Header />
      <div className="app__body">
        <Sidebar />
        <div className="inside_app">
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
