import React, { useState, useEffect } from "react";
import "./CSS/PurchaseOrdersVendorListingsView.css";
import {
  CostPriceTimelineItem,
  Listing,
  ListingPage,
  PurchaseOrderItemWithLisiting,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

const ListingCard: React.FC<{
  purchaseOrderListing: PurchaseOrderItemWithLisiting;
  handleCardClick: (listing: PurchaseOrderItemWithLisiting) => void;
}> = ({ purchaseOrderListing, handleCardClick }) => (
  <div
    className="listings-view-card vendor-listing-card"
    onClick={() => handleCardClick(purchaseOrderListing)}
  >
    <div className="listings-img-container">
      <img src={purchaseOrderListing.listing.img_wdim} alt="Product Image" />
    </div>

    <div className="listings-view-card-title">
      <h3
        className={`sku-id-title ${
          purchaseOrderListing.listing.sku_type == "MULTI"
            ? ""
            : purchaseOrderListing.listing.available_quantity === 0
            ? "not-available"
            : "available"
        }`}
      >
        {purchaseOrderListing.listing.sku_id}
      </h3>
    </div>
    <p>OPS Tag: {purchaseOrderListing.listing.ops_tag}</p>
    {purchaseOrderListing.listing.sku_type == "MULTI" ? (
      <p>MULTI</p>
    ) : (
      <p>
        Available Quantity: {purchaseOrderListing.listing.available_quantity}
      </p>
    )}
    {/* <p>Available Quantity: {purchaseOrderListing.listing.available_quantity}</p> */}

    <div className="required-quantity-container">
      <p>Required Quantity : </p>
      <input
        type="text"
        name="vendor_cost_price"
        value={purchaseOrderListing.required_quantity}
        //   onChange={handleChange}
        //   disabled={!editMode}
      />
    </div>
    <p className="last-modified">
      Last Modified:{" "}
      {DateTimeUtils.formatDateTime(purchaseOrderListing.updated_at)}
    </p>
  </div>
);

type ListingsViewProps = {
  publishedListings: PurchaseOrderItemWithLisiting[];
  onCardClick: (selectedListing: PurchaseOrderItemWithLisiting) => void;
};

export default function PurchaseOrdersPublishedItemsView({
  publishedListings,
  onCardClick,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleCardClick = async (listing: PurchaseOrderItemWithLisiting) => {
    onCardClick(listing);
  };

  return (
    <div>
      {publishedListings && publishedListings.length > 0 && (
        <div className="vendor-listings-card-container">
          {publishedListings.map((publishedListing, index) => (
            <ListingCard
              key={index}
              purchaseOrderListing={publishedListing}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}

      {publishedListings && publishedListings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
