import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import {
  PoItem,
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemWithLisiting,
  Vendor,
} from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { requestHeaders } from "../../NetworkUtils/Requests";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  itemPage: {
    position: "relative",
    width: "100%",
    height: "100%",
    // backgroundImage: 'url("/catalogue/bg1.png")',
    // backgroundSize: "cover", // Adjust this property based on your needs
    // backgroundPosition: "center", // Adjust this property based on your needs
    // opacity: 0.5,
  },
  imageContainer: {
    width: "30%", // Adjust the width as needed
    marginRight: 10,
    borderRight: "1px solid #ccc",
  },
  detailsContainer: {
    width: "70%", // Adjust the width as needed
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 20,
  },
  productImage: {
    width: "80%", // Adjust the image width as needed
    height: "auto",
  },
  poHeaderText: {
    fontSize: 22, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
  },
  poIdText: {
    fontSize: 16, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
    marginTop: 4,
    marginBottom: 8,
  },
  poDate: {
    marginTop: 4,
  },
  poItemText: {
    fontSize: 12, // Adjust the font size for item details
    marginBottom: 3,
  },
  requiredQuantity: {
    fontSize: 14, // Adjust the font size for item details
    marginBottom: 8,
    marginTop: 10,
  },
  itemContainer: {
    flexDirection: "row",
    margin: 5,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    border: "1px solid #ccc",
  },
  logoContainer: {
    marginBottom: 10,
    alignItems: "flex-start",
    width: "48%",
  },
  logoImage: {
    height: 80, // Adjust the height as needed
  },
  addressContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  deliveryTermsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  addressContainerAddressAndSummary: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
  },
  addressColumnParent: {
    width: "49%", // Adjust the width as needed
  },
  addressColumn: {
    border: "1px solid #ccc",
    borderRadius: 5,
    marginBottom: 10,
  },
  itemInfoRow: {
    marginBottom: 10,
    padding: 10,
    color: "black",
    fontWeight: "ultrabold",
    fontSize: 12,
  },

  itemInfoColumn: {
    marginBottom: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  poIdColumn: {
    width: "48%", // Adjust the width as needed
    marginBottom: 10,
    fontSize: 12,
    border: "1px solid #ccc",
  },
  addressColumnDetails: {
    padding: 10,
    fontSize: 12,
  },
  purchaseOrderTerms: {
    padding: 10,
    fontSize: 12,
  },
  addressTitle: {
    fontWeight: "ultrabold",
    fontSize: 12,
    marginBottom: 5,
    backgroundColor: "black",
    color: "#FFFFFF",
    padding: 6,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  addressText: {
    fontSize: 10,
  },
  labelAndDateContainer: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: 12,
  },
  vendorName: {
    marginBottom: 5,
    fontWeight: "ultrabold",
  },
  purchaseOrderTermsText: {
    marginBottom: 5,
  },
  itemsContainer: {
    // position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
  },
});

const PurchaseOrderPDF_v3: React.FC<{
  chunkedPoItems: PurchaseOrderItemWithLisiting[][];
  purchaseOrder: PurchaseOrder | null;
  vendor: Vendor | null;
  totalItems: number;
  totalQuantity: number;
}> = ({ chunkedPoItems, purchaseOrder, vendor, totalItems, totalQuantity }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.addressContainer}>
          <View style={styles.logoContainer}>
            <Image
              src="/ecraft_complete_logo.PNG" // Update this with the path to your logo
              style={styles.logoImage}
            />
          </View>
          <View style={styles.labelAndDateContainer}>
            <Text style={styles.poHeaderText}>Purchase Order</Text>
            <Text style={styles.poIdText}>
              P.O. ID - {purchaseOrder ? purchaseOrder.po_id : ""}
            </Text>
            <Text style={styles.poDate}>
              P.O. date :{" "}
              {purchaseOrder
                ? DateTimeUtils.formatDateOnly(purchaseOrder.date_published)
                : ""}
            </Text>
            <Text style={styles.poDate}>
              Delivery Date -{" "}
              {purchaseOrder
                ? DateTimeUtils.formatDateTime(purchaseOrder.expected_date)
                : ""}
            </Text>
          </View>
        </View>

        <View style={styles.addressContainerAddressAndSummary}>
          {/* Billing Address */}
          <View style={styles.addressColumnParent}>
            <View style={styles.addressColumn}>
              <Text style={styles.addressTitle}>Billing Address</Text>
              <View style={styles.addressColumnDetails}>
                <Text>Eunoia Crafts India Private Ltd.</Text>
                <Text>44, Jamna Dairy, Ajmer Road</Text>
                <Text>Sodala, Jaipur-302006</Text>
                <Text>Rajasthan, India</Text>
                <Text>GST - 08AAGCE1266P1Z7</Text>
              </View>
            </View>
            <View style={styles.addressColumn}>
              <Text style={styles.addressTitle}>Shipping Address</Text>
              <View style={styles.addressColumnDetails}>
                <Text>44, Jamna Dairy, Ajmer Road</Text>
                <Text>Sodala, Jaipur-302006</Text>
                <Text>Rajasthan, India</Text>
                <Text>+91 8562810909</Text>
              </View>
              {/* Add your billing address details here */}
            </View>
          </View>
          <View style={styles.addressColumnParent}>
            {/* Shipping Address */}
            <View style={styles.addressColumn}>
              <Text style={styles.addressTitle}>Vendor Details</Text>
              {vendor && (
                <View style={styles.addressColumnDetails}>
                  <Text style={styles.vendorName}>{vendor.vendor_name}</Text>
                  <Text>{vendor.vendor_address_line}</Text>
                  <Text>
                    {vendor.vendor_city}, {vendor.vendor_state}
                  </Text>
                  <Text>{vendor.vendor_postal_code}</Text>
                  <Text>GST - {vendor.vendor_gstin}</Text>
                  <Text>{vendor.vendor_contact_number}</Text>
                </View>
              )}
              {/* Add your billing address details here */}
            </View>
            <View style={styles.addressColumn}>
              <Text style={styles.addressTitle}>Summary</Text>
              <View style={styles.addressColumnDetails}>
                <Text>Total Items - {totalItems}</Text>
                <Text>Total Quantity - {totalQuantity}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.deliveryTermsContainer}>
          <View style={styles.addressColumn}>
            <Text style={styles.addressTitle}>Delivery Terms - </Text>
            <View style={styles.purchaseOrderTerms}>
              <Text style={styles.purchaseOrderTermsText}>
                1) Kindly adhere to the specified quantity mentioned in the
                Purchase Order, as quantities not aligned with the PO will not
                be admissible.
              </Text>
              <Text style={styles.purchaseOrderTermsText}>
                2) Please ensure that all items are sent in suitable primary
                packaging for their protection.
              </Text>
              <Text style={styles.purchaseOrderTermsText}>
                3) We kindly request you to include the attached Purchase Order
                ID when sending your invoice.
              </Text>
              <Text style={styles.purchaseOrderTermsText}>
                4) Kindly provide a complete and accurate tax invoice for the
                transaction. Please note that without the proper invoice, the
                merchandise will not be accepted.
              </Text>
              <Text style={styles.purchaseOrderTermsText}>
                5) To facilitate smooth processing, kindly ensure that the
                address and GSTIN on the bill match the details provided in the
                Purchase Order.
              </Text>
              <Text style={styles.purchaseOrderTermsText}>
                6) E-way bills are mandatory for invoices exceeding a value of
                50,000 INR.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>

    {chunkedPoItems.map((singleChunk, index) => (
      <Page size="A4" style={styles.itemPage}>
        <Image src="/catalogue/purchase_order_top_design_1_main.png" />
        <View style={styles.itemsContainer}>
          {singleChunk.map((poItem, index) => (
            <View key={index} style={styles.itemContainer}>
              <View style={styles.imageContainer}>
                <Image
                  src={{
                    uri: `${
                      requestHeaders.rooturl
                    }images/${poItem.listing.img_wdim.substring(
                      poItem.listing.img_wdim.lastIndexOf("/") + 1
                    )}`,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                  style={styles.productImage}
                />
              </View>
              <View style={styles.detailsContainer}>
                <Text
                  style={styles.requiredQuantity}
                >{`Required Quantity: ${poItem.required_quantity}`}</Text>
                <Text
                  style={styles.poItemText}
                >{`SKU ID: ${poItem.listing.sku_id}`}</Text>
                {/* <Text
                  style={styles.poItemText}
                >{`L.W.H. : ${poItem.listing.length} * ${poItem.listing.width} * ${poItem.listing.height}`}</Text> */}
                <Text
                  style={styles.poItemText}
                >{`L.W.H. : ${poItem.listing.dimension}`}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    ))}
  </Document>
);
export default PurchaseOrderPDF_v3;
