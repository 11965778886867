import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/CatalogueDetailsPage.css";
import { Alert, Button, Form, Offcanvas } from "react-bootstrap";
import {
  PurchaseOrder,
  CatalogueItem,
  Catalogue,
  CatalogueTheme,
} from "../../atomic_components/types";

import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import DateTimeUtils from "../../utils/DateTimeUtils";
import Header from "../Header";
import { PDFDownloadLink } from "@react-pdf/renderer";

import CataloguesSearchBar from "./CataloguesSearchBar";
import CatalogueNetworkUtils from "./NetworkUtils/CatalogueNetworkUtils";
import CatalogueItemsView from "./CatalogueItemsView";
import CataloguePDF from "./CataloguePDF";
import CataloguePDF_v2 from "./CataloguePDF_v2";
import CataloguePDF_v3 from "./CataloguePDF_v3";
import FilteredDropdown from "../Vendors/FilteredDropdown";
import CatalogueThemesDropdown from "./CatalogueThemesDropdown";

interface RouteParams {
  catalogue_id: string;
}

function CatalogueDetailsPage() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { catalogue_id } = useParams<RouteParams>();

  const [selectedImageKey, setSelectedImageKey] = useState<string>("img_wdim");
  const [catalogueItems, setcatalogueItems] = useState<CatalogueItem[]>([]);
  const [catalogueThemes, setCatalogueThemes] = useState<CatalogueTheme[]>([]);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);
  const [providedSelectedValue, setProvidedSelectedValue] =
    useState<string>("");
  const [selectedCatalogueTheme, setSelectedCatalogueTheme] =
    useState<CatalogueTheme | null>(null);
  const [filteredcatalogueItems, setFilteredcatalogueItemss] = useState<
    CatalogueItem[]
  >([]);
  const [currentCatalogue, setCurrentCatalogue] = useState<Catalogue | null>(
    null
  );
  const [chunkedCatalogueItems, setChunkedCatalogueItems] = useState<
    CatalogueItem[][]
  >([]);

  const [generatePDF, setGeneratePDF] = useState<boolean>(false);
  const [isAddWaterMarkChecked, setWaterMarkChecked] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const catalogueNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    const fetchCatalogueById = async (catalogueId: string) => {
      console.log(catalogueId);
      try {
        const response = await catalogueNetworkUtils.getCatalogueById(
          catalogueId
        );
        console.log(response.data);
        setCurrentCatalogue(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchCatalogueById(catalogue_id);
  }, [catalogue_id]);

  useEffect(() => {
    const getcatalogueItemsByCatalogueId = async (catalogueId: number) => {
      try {
        const response = await catalogueNetworkUtils.getCatalogueItems(
          catalogueId.toString()
        );

        console.log(response.data);
        setcatalogueItems(response.data);
        setFilteredcatalogueItemss(response.data);
      } catch (error) {
        console.error(
          "Error in getting catalogue items by catalogueId : ",
          error
        );
      }
    };
    const getCatalogueThemes = async () => {
      try {
        const response = await catalogueNetworkUtils.getCatalogueThemes();
        console.log(response.data);
        setCatalogueThemes(response.data);
      } catch (error) {
        console.error("Error in getting catalogue themes", error);
      }
    };

    if (currentCatalogue) {
      getcatalogueItemsByCatalogueId(currentCatalogue.id);
      getCatalogueThemes();
    }
  }, [currentCatalogue]);

  useEffect(() => {
    setChunkedCatalogueItems(chunkArray(catalogueItems, 3));
  }, [catalogueItems]);

  useEffect(() => {
    setSelectedCatalogueTheme(catalogueThemes[0]);
  }, [catalogueThemes]);

  const handleSearch = (query: string) => {
    console.log("purchase order item searched ", query);
    if (query.length == 0)
      setFilteredcatalogueItemss(_.cloneDeep(catalogueItems));
    else setFilteredcatalogueItemss(filtercatalogueItemss(query));
  };

  const filtercatalogueItemss = (query: string) => {
    query = query.toLowerCase();
    return catalogueItems.filter((catalogue) => {
      const skuMatches = catalogue.listing.sku_id.toLowerCase().includes(query);

      const opsTagMatches = catalogue.listing.ops_tag
        .toLowerCase()
        .includes(query);

      return skuMatches || opsTagMatches;
    });
  };

  const chunkArray = (
    array: CatalogueItem[],
    size: number
  ): CatalogueItem[][] => {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, index * size + size)
    );
  };

  const onCardClick = (currentItem: CatalogueItem) => {
    console.log("card clicked: ", currentItem);
    // setModalShow(true);
    // setSelectedListing(currentListing);
  };

  const handleshowCanvas = () => {
    setShowCanvas(false);
  };

  const handleCatalogueThemeFromDropdownSelected = (value: CatalogueTheme) => {
    console.log("Selected Value:", value);
    setSelectedCatalogueTheme(value);
  };

  const handleImageError = (imageKey: string) => {
    if (selectedCatalogueTheme) {
      const updatedCatalogueTheme = { ...selectedCatalogueTheme };
      updatedCatalogueTheme.theme_pages[imageKey] =
        "http://tech.intellozene.com/Media/na.png";
      setSelectedCatalogueTheme(updatedCatalogueTheme);
    }
  };

  const handleImageClick = (imageUrl: string) => {
    // setSelectedImage(imageUrl);
    console.log(imageUrl, "clicked");
  };

  const handleImageTypeRadioChange = (imageKey: string) => {
    setSelectedImageKey(imageKey);
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        <div className="main-container">
          <div className="vendor-action-container">
            {catalogueItems.length > 0 && (
              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <div className="vendor-details-button-container">
                    <Button onClick={() => setShowCanvas(true)}>
                      Generate Catalogue PDF
                    </Button>
                  </div>
                </div>
                <div className="grid-1-cells">
                  {catalogueItems.length != 0 && (
                    <div className="grid-1-cells">
                      <Alert variant="light" className="vendor-info">
                        <div>
                          <b>Total SKUs : </b>{" "}
                          {catalogueItems.length.toString()}
                        </div>
                      </Alert>
                    </div>
                  )}
                </div>
                <div className="grid-1-cells">
                  <div className="grid-item-details-card">
                    <Form>
                      {[
                        "img_hd",
                        "img_white",
                        "img_wdim",
                        "img_link1",
                        "img_link2",
                      ].map((imageKey: string) => (
                        <Form.Check
                          type="radio"
                          key={imageKey}
                          label={imageKey}
                          name="group1"
                          checked={selectedImageKey === imageKey}
                          onChange={() => {
                            handleImageTypeRadioChange(imageKey);
                          }}
                        />
                      ))}
                    </Form>
                  </div>
                </div>

                <div className="grid-1-cells">
                  <div className="grid-item-details-card">
                    <label>Change unit price in bulk : </label>
                    <label>Add/Subtract by value : </label>
                    <label>Add/Subtract by percent : </label>
                  </div>
                </div>
              </div>
            )}
            {currentCatalogue && (
              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <h4 className="vendor_id">Catalogue Details</h4>
                </div>
                <div className="grid-1-cells">
                  <div className="grid-item-details-card">
                    <label>Catalogue Id</label>
                    <input
                      type="text"
                      name="catalogue_id"
                      value={currentCatalogue.id}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Catalogue Name</label>
                    <input
                      type="text"
                      name="catalogue_name"
                      value={currentCatalogue.catalogue_name}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Catalogue Description</label>
                    <input
                      type="text"
                      name="catalogue_description"
                      value={currentCatalogue.catalogue_description}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Min Price</label>
                    <input
                      type="text"
                      name="catalogue_min_price"
                      value={currentCatalogue.min_price}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Max Price</label>
                    <input
                      type="text"
                      name="catalogue_max_price"
                      value={currentCatalogue.max_price}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Created By</label>
                    <input
                      type="text"
                      name="catalogue_created_by"
                      value={currentCatalogue.created_by}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Date Created</label>
                    <input
                      type="text"
                      name="po_date_created"
                      value={DateTimeUtils.formatDateTime(
                        currentCatalogue.created_at
                      )}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="vendor-skus-container">
            <div className="vendor-listings-search-bar-container">
              <CataloguesSearchBar
                onSearch={handleSearch}
              ></CataloguesSearchBar>
            </div>

            <div className="listings-container purchase-order-vendor-listings-container">
              <CatalogueItemsView
                catalogueItems={filteredcatalogueItems}
                onCardClick={onCardClick}
                selectedImageKey={selectedImageKey}
              />
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={showCanvas}
        onHide={handleshowCanvas}
        // backdrop="static"
        placement="end"
        style={{ width: "70%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <p>Catalogue Generation</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container">
            <Form>
              <Form.Check
                type="switch"
                label="Add ecraftIndia's watermark ?"
                checked={isAddWaterMarkChecked}
                onChange={() => {
                  setWaterMarkChecked(!isAddWaterMarkChecked);
                }}
              />
            </Form>
            <CatalogueThemesDropdown
              options={catalogueThemes}
              onItemSelected={handleCatalogueThemeFromDropdownSelected}
              providedSelectedValue={providedSelectedValue}
            />
            {selectedCatalogueTheme && (
              <div className="grid-4-cells">
                {["first_page", "second_page", "third_page", "theme_page"].map(
                  (imageKey: string) => (
                    <div className="grid-item-details-card">
                      <div
                        key={imageKey}
                        className={`image-placeholder-catalogue`}
                        onClick={() => handleImageClick(imageKey)}
                      >
                        <img
                          src={selectedCatalogueTheme.theme_pages[
                            imageKey
                          ].toString()}
                          alt={`Image ${selectedCatalogueTheme.theme_name}`}
                          onError={() => handleImageError(imageKey)}
                        />
                        <div className="theme-name">{imageKey}</div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            {!generatePDF && (
              <Button className="mt-4" onClick={() => setGeneratePDF(true)}>
                Generate PDF
              </Button>
            )}

            {generatePDF &&
              selectedCatalogueTheme &&
              chunkedCatalogueItems &&
              chunkedCatalogueItems.length > 0 && (
                <div className="mt-4">
                  <PDFDownloadLink
                    document={
                      <CataloguePDF_v3
                        chunkedCatalogueItems={chunkedCatalogueItems}
                        catalogue={currentCatalogue}
                        isAddWaterMarkChecked={isAddWaterMarkChecked}
                        selectedCatalogueTheme={selectedCatalogueTheme}
                        selectedImageKey={selectedImageKey}
                      />
                    }
                    fileName="test"
                  >
                    {({ blob, url, loading, error }) => (
                      <button type="button" disabled={loading}>
                        {loading ? "Loading document..." : "Download PDF"}
                      </button>
                    )}
                  </PDFDownloadLink>
                </div>
              )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default CatalogueDetailsPage;
