import React, { useState, useEffect } from "react";
import "./CSS/PurchaseOrdersVendorListingsView.css";
import {
  CostPriceTimelineItem,
  Listing,
  ListingPage,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

const ListingCard: React.FC<{
  vendorListing: VendorListing;
  handleCardClick: (listing: VendorListing) => void;
}> = ({ vendorListing, handleCardClick }) => (
  <div
    className="listings-view-card vendor-listing-card"
    onClick={() => handleCardClick(vendorListing)}
  >
    <div className="listings-img-container">
      <img src={vendorListing.listing.img_wdim} alt="Product Image" />
    </div>

    <div className="listings-view-card-title">
      <h3
        className={`sku-id-title ${
          vendorListing.listing.sku_type == "MULTI"
            ? ""
            : vendorListing.listing.available_quantity === 0
            ? "not-available"
            : "available"
        }`}
      >
        {vendorListing.listing.sku_id}
      </h3>
    </div>
    <p>OPS Tag: {vendorListing.listing.ops_tag}</p>
    {vendorListing.listing.sku_type == "MULTI" ? (
      <p className="purchase-order-vendor-listing-card-text">MULTI</p>
    ) : (
      <p className="purchase-order-vendor-listing-card-text">
        Available Quantity: {vendorListing.listing.available_quantity}
      </p>
    )}

    <p className="purchase-order-vendor-listing-card-text">
      Cost Price : {vendorListing.cost_price}
    </p>
    <p>
      {` L.W.H : ${vendorListing.listing.length} * ${vendorListing.listing.width} * ${vendorListing.listing.height}`}
    </p>
    <p className="last-modified">
      Last Modified: {DateTimeUtils.formatDateTime(vendorListing.updated_at)}
    </p>
  </div>
);

type ListingsViewProps = {
  vendorListings: VendorListing[];
  onCardClick: (selectedListing: VendorListing) => void;
};

export default function PurchaseOrderVendorListingsView({
  vendorListings,
  onCardClick,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleCardClick = async (listing: VendorListing) => {
    onCardClick(listing);
  };

  return (
    <div>
      {vendorListings && vendorListings.length > 0 && (
        <div className="vendor-listings-card-container">
          {vendorListings.map((vendorListing, index) => (
            <ListingCard
              key={index}
              vendorListing={vendorListing}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}

      {vendorListings && vendorListings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
