import { ActionType } from "../action-types/index";
import {ActionInterface} from "../actions-interface/index"

const initialState = false ;

const WindowSizeReducer = (state : boolean = initialState, action : ActionInterface)=>{

    switch(action.type){
        case ActionType.WINDOW_SMALL:
            return action.payload;        
        default:
            return state
    }
}

export default WindowSizeReducer