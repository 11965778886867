import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CatalogueTheme } from "../../atomic_components/types";

interface CatalogueThemesDropdownProps {
  options: CatalogueTheme[];
  onItemSelected: (value: CatalogueTheme) => void;
  providedSelectedValue: string;
}

const CatalogueThemesDropdown: React.FC<CatalogueThemesDropdownProps> = ({
  options,
  onItemSelected,
  providedSelectedValue,
}) => {
  const [filter, setFilter] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>(
    providedSelectedValue
  );

  useEffect(() => {
    setSelectedValue(providedSelectedValue);
  }, [providedSelectedValue]);

  const filteredOptions = options.filter((option) =>
    option.theme_name.toLowerCase().includes(filter.toLowerCase())
  );

  const handleSelect = (value: string | null) => {
    console.log("logging selected option ", value);
    if (value) {
      setSelectedValue(value);
      console.log(value);
      options.forEach((option) => {
        if (option.theme_name === value) {
          onItemSelected(option);
        }
      });
    }
  };

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle
        variant="primary"
        id="dropdown-basic"
        style={{ width: "100%", fontSize: "14px" }}
      >
        {selectedValue || "Select CatalogueTheme"}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
      >
        <input
          type="text"
          className="form-control mb-2"
          placeholder="Filter options..."
          value={filter}
          style={{ width: "100%" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilter(e.target.value)
          }
        />
        {filteredOptions.map((option, index) => (
          <Dropdown.Item key={index} eventKey={option.theme_name as any}>
            {option.theme_name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CatalogueThemesDropdown;
