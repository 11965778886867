import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

interface FilteredDropdownProps {
  options: string[];
  onItemSelected: (value: string) => void;
}

const FilteredDropdown: React.FC<FilteredDropdownProps> = ({
  options,
  onItemSelected,
}) => {
  const [filter, setFilter] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(filter.toLowerCase())
  );

  const handleSelect = (value: string | null) => {
    console.log("logging selected option ", value);
    if (value) {
      setSelectedValue(value);
      onItemSelected(value); // Pass the selected value to the parent component
    }
  };

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle
        variant="primary"
        id="dropdown-basic"
        style={{ width: "100%", fontSize: "14px" }}
      >
        {selectedValue || "Select an SKU to map"}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ width: "100%" }}>
        <input
          type="text"
          className="form-control mb-2"
          placeholder="Filter options..."
          value={filter}
          style={{ width: "100%" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilter(e.target.value)
          }
        />
        {filter.length > 2 &&
          filteredOptions.map((option, index) => (
            <Dropdown.Item key={index} eventKey={option as any}>
              {option}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilteredDropdown;
