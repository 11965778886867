import { SearchParams } from "../../atomic_components/types";
import { SearchTextActionTypes } from "../action-types/index";
import { SearchTextAction } from "../actions-interface/index";

const initialState = {
  search_string : "",
  item_count : 0,
  expected_item_count : 0,
  current_page_num: 1,  
};

const listingSearchParametersReducer2 = (
  state: SearchParams = initialState,
  action: SearchTextAction
) => {
  switch (action.type) {
    case SearchTextActionTypes.SET_SEARCH_PARAMS:      
      return action.payload;
    default:
      return state;
  }  
};
export default listingSearchParametersReducer2;
