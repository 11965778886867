// import { format } from 'date-fns';
import { format } from 'date-fns-tz';
class DateTimeUtils{
      static formatDateTime(dateString: string | undefined): string {
        try{
          if(dateString){
              const dateObject = new Date(dateString);
              const options: Intl.DateTimeFormatOptions = {
                  hour: "2-digit",
                  minute: "2-digit",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  timeZone: 'Asia/Kolkata' // Set the time zone to India (IST)
              };
              return new Intl.DateTimeFormat("en-IN", options).format(dateObject)
          }
        }catch(error){
          return "";
        }
        return "";
      }

      static formatDateOnly(dateString: string | undefined): string {
        try{
          if(dateString){
              const dateObject = new Date(dateString);
              const options: Intl.DateTimeFormatOptions = {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  timeZone: 'Asia/Kolkata' // Set the time zone to India (IST)
              };
              return new Intl.DateTimeFormat("en-IN", options).format(dateObject)
          }
        }catch(error){
          return "";
        }
        return "";
      }

      static formatDateTimeForDb(date : Date): string {
        const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss');
        return formattedDate;
      }


      static getCurrentTimeFormatted = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      static getCurrentDateTime = () => {
        const dateObject = new Date();
        const options: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "short",
            day: "numeric",
            timeZone: 'Asia/Kolkata' // Set the time zone to India (IST)
        };        
        return new Intl.DateTimeFormat("en-IN", options).format(dateObject);
      }

      static getCurrentDateOnly = () => {
        const dateObject = new Date();
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "short",
            day: "numeric",
            timeZone: 'Asia/Kolkata' // Set the time zone to India (IST)
        };        
        return new Intl.DateTimeFormat("en-IN", options).format(dateObject);
      }

      static getDateStatus = (expiryDateString : string) => {
        const currentDate = new Date();
        const expiryDate = new Date(expiryDateString);
      
        // Calculate the difference in milliseconds between the current date and expiry date
        const timeDifference = expiryDate.getTime() - currentDate.getTime();
      
        // Calculate the difference in days
        const daysDifference = timeDifference / (1000 * 3600 * 24);
      
        // if (daysDifference < 0) {
        //   return 'Expired';
        // } else if (daysDifference <= 5) {
        //   return 'Expiring in the next 5 days';
        // } else {
        //   return 'Valid for more than 5 days';
        // }

        return daysDifference;
      }
}

export default DateTimeUtils;