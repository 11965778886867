import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import {
  Catalogue,
  CatalogueItem,
  CatalogueTheme,
} from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  itemPage: {
    position: "relative",
    width: "100%",
    height: "100%",
    // backgroundImage: 'url("/catalogue/bg1.png")',
    // backgroundSize: "cover", // Adjust this property based on your needs
    // backgroundPosition: "center", // Adjust this property based on your needs
    // opacity: 0.5,
  },
  // container: {
  //   position: "relative",
  // },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  imageContainer: {
    width: "35%", // Adjust the width as needed
    marginRight: 10,
  },
  detailsContainer: {
    width: "65%", // Adjust the width as needed
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  productImage: {
    width: "90%", // Adjust the image width as needed
    height: "auto",
  },
  poHeaderText: {
    fontSize: 16, // Adjust the font size for the Purchase Order text
    fontWeight: "ultrabold",
  },
  catalogueItemText: {
    fontSize: 12, // Adjust the font size for item details
    marginBottom: 3,
    marginRight: 5,
  },
  requiredQuantity: {
    fontSize: 14, // Adjust the font size for item details
    marginBottom: 3,
  },
  itemsContainer: {
    // position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
  },
  itemContainer: {
    flexDirection: "row",
    margin: 5,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
    border: "1px solid #ccc",
  },
  frontPageContainer: {
    width: "100%",
    height: "100%",
  },
  frontPageImage: {
    width: "100%", // Set the width to fill the page
    height: "100%", // Set the height to fill the page
    objectFit: "cover",
  },
  addressContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  addressColumn: {
    width: "48%", // Adjust the width as needed
    border: "1px solid #ccc",
    borderRadius: 5,
    marginBottom: 10,
  },
  poIdColumn: {
    width: "48%", // Adjust the width as needed
    marginBottom: 10,
    fontSize: 12,
  },
  addressColumnDetails: {
    padding: 10,
    fontSize: 12,
  },
  addressTitle: {
    fontWeight: "ultrabold",
    fontSize: 12,
    marginBottom: 5,
    backgroundColor: "#EC1B30",
    color: "#FFFFFF",
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  addressText: {
    fontSize: 10,
  },
  labelAndDateContainer: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: 12,
  },
  watermarkImage: {
    position: "absolute",
    width: "100%", // Set the width to fill the page
    height: "100%", // Set the height to fill the page
    objectFit: "cover",
    opacity: 0.5,
    top: 0,
    left: 0,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1, // Ensure the watermark is positioned above other elements
  },
  overlayContent: {
    position: "absolute",
    width: "100%", // Set the width to fill the page
    height: "100%",
  },
});

const randomFrontPageImageFunction = () => {
  const startRange = 1;
  const endRange = 4;
  const index =
    Math.floor(Math.random() * (endRange - startRange + 1)) + startRange;
  return `/catalogue/catalogue_front_page_${index}.PNG`;
};

const randomSecondPageImageFunction = () => {
  const startRange = 1;
  const endRange = 4;
  const index =
    Math.floor(Math.random() * (endRange - startRange + 1)) + startRange;
  return `/catalogue/catalogue_second_page_${index}.PNG`;
};

const randomThirdPageImageFunction = () => {
  const startRange = 1;
  const endRange = 4;
  const index =
    Math.floor(Math.random() * (endRange - startRange + 1)) + startRange;
  return `/catalogue/catalogue_third_page_${index}.PNG`;
};

const CataloguePDF_v3: React.FC<{
  chunkedCatalogueItems: CatalogueItem[][];
  catalogue: Catalogue | null;
  isAddWaterMarkChecked: Boolean;
  selectedCatalogueTheme: CatalogueTheme;
  selectedImageKey: string;
}> = ({
  chunkedCatalogueItems,
  catalogue,
  isAddWaterMarkChecked,
  selectedCatalogueTheme,
  selectedImageKey,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          src={{
            uri: `http://localhost:8000/api/themes/${selectedCatalogueTheme.theme_pages.first_page.substring(
              selectedCatalogueTheme.theme_pages.first_page.lastIndexOf("/") + 1
            )}`,
            method: "GET",
            headers: { "Cache-Control": "no-cache" },
            body: "",
          }}
          style={styles.frontPageImage}
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <Image
          src={{
            uri: `http://localhost:8000/api/themes/${selectedCatalogueTheme.theme_pages.second_page.substring(
              selectedCatalogueTheme.theme_pages.second_page.lastIndexOf("/") +
                1
            )}`,
            method: "GET",
            headers: { "Cache-Control": "no-cache" },
            body: "",
          }}
          style={styles.frontPageImage}
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <Image
          src={{
            uri: `http://localhost:8000/api/themes/${selectedCatalogueTheme.theme_pages.third_page.substring(
              selectedCatalogueTheme.theme_pages.third_page.lastIndexOf("/") + 1
            )}`,
            method: "GET",
            headers: { "Cache-Control": "no-cache" },
            body: "",
          }}
          style={styles.frontPageImage}
        />
      </Page>

      {chunkedCatalogueItems.map((singleChunk, index) => (
        <Page size="A4" style={styles.itemPage}>
          <View style={styles.overlayContent}>
            {isAddWaterMarkChecked && (
              <Image
                src="/catalogue/catalogue_watermark.png"
                style={styles.watermarkImage}
              />
            )}
          </View>

          <Image src="/catalogue/catalogue_top_design_1_main.png" />

          <View style={styles.itemsContainer}>
            {singleChunk.map((catalogueItem, index) => (
              <View key={index} style={styles.itemContainer}>
                <View style={styles.imageContainer}>
                  {selectedImageKey == "img_wdim" && (
                    <Image
                      src={{
                        uri: `http://localhost:8000/api/images/${catalogueItem.listing.img_wdim.substring(
                          catalogueItem.listing.img_wdim.lastIndexOf("/") + 1
                        )}`,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                      style={styles.productImage}
                    />
                  )}
                  {selectedImageKey == "img_hd" && (
                    <Image
                      src={{
                        uri: `http://localhost:8000/api/images/${catalogueItem.listing.img_hd.substring(
                          catalogueItem.listing.img_hd.lastIndexOf("/") + 1
                        )}`,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                      style={styles.productImage}
                    />
                  )}
                  {selectedImageKey == "img_link1" && (
                    <Image
                      src={{
                        uri: `http://localhost:8000/api/images/${catalogueItem.listing.img_link1.substring(
                          catalogueItem.listing.img_link1.lastIndexOf("/") + 1
                        )}`,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                      style={styles.productImage}
                    />
                  )}
                  {selectedImageKey == "img_link2" && (
                    <Image
                      src={{
                        uri: `http://localhost:8000/api/images/${catalogueItem.listing.img_link2.substring(
                          catalogueItem.listing.img_link2.lastIndexOf("/") + 1
                        )}`,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                      style={styles.productImage}
                    />
                  )}
                  {selectedImageKey == "img_white" && (
                    <Image
                      src={{
                        uri: `http://localhost:8000/api/images/${catalogueItem.listing.img_white.substring(
                          catalogueItem.listing.img_white.lastIndexOf("/") + 1
                        )}`,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                      style={styles.productImage}
                    />
                  )}
                </View>

                <View style={styles.detailsContainer}>
                  <Text
                    style={styles.catalogueItemText}
                  >{`Description: ${catalogueItem.listing.description}`}</Text>
                  {/* <Text
                    style={styles.requiredQuantity}
                  >{`M.O.Q (Minimum Order Quantity): ${catalogueItem.moq}`}</Text> */}
                  <Text
                    style={styles.catalogueItemText}
                  >{`SKU ID: ${catalogueItem.listing.sku_id}`}</Text>
                  {/* <Text
                    style={styles.catalogueItemText}
                  >{`Unit Price(in Rs): ${catalogueItem.listing.bulk_price}`}</Text> */}
                  {/* <Text style={styles.catalogueItemText}>
                    {`L.W.H: ${catalogueItem.listing.length} * ${catalogueItem.listing.width} * ${catalogueItem.listing.height}`}
                    {`L.W.H. : ${catalogueItem.listing.dimension}`}
                  </Text> */}
                </View>
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};
export default CataloguePDF_v3;
