import { ActionType } from "../action-types/index";
import {ActionInterface} from "../actions-interface/index"
import {POType} from "../../atomic_components/types"

const initialState = {
    po_id : 0,
    company_id: 0,
    team_id: 0,
    submitted_by: 0,
    status: "",
    expire_at : "",
    created_at: "",
    updated_at: "" 
} ;

const reducer = (state : POType = initialState, action : ActionInterface)=>{

    switch(action.type){
        case ActionType.PO :
            return action.payload;        
        default:
            return state
    }
}

export default reducer