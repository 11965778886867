import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { PurchaseOrder, Specialty, Vendor, VendorListing } from "../../../atomic_components/types";


class PurchaseOrderNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async createNewPurchaseOrder(newPurchaseOrder: any) {
    try {
      let url = `${requestHeaders.rooturl}${requests.createNewPurchaseOrder}`;
      const response = await this.api.post(url, newPurchaseOrder, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from createNewPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurchaseOrderById(poId : string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurchaseOrderById}${poId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurchaseOrderById");
      console.error("Error:", error);
      throw error;
    }
  }

  async addItemToPurchaseOrder(purchaseOrderItem : any, poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.addItemToPurchaseOrder}${poId}`;
      const response = await this.api.put(url, purchaseOrderItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addItemToPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async deleteItemFromPurchaseOrder(purchaseOrderItem : any, poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.GetPOSummary}${poId}`;
      const response = await this.api.post(url, purchaseOrderItem, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deleteItemFromPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async getPurcahseOrderItems(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.getAddedItemsOfAPurchaseOrder}${poId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrderItems");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurcahseOrderItemsWithListing(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.getAddedItemsOfAPurchaseOrderWithListing}${poId}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrderItemsWithListing");
      console.error("Error:", error);
      throw error;
    }
  }

  async publishPo(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.publishPo}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from publishPo");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async republishPo(poId: string, userId : string){
    try {
      // const queryParams: Record<string, any> = {
      //   userId: userId, // New page value
      // };
      let url = `${requestHeaders.rooturl}${requests.republishPo}${poId}?userId=${userId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from republishPo");
      console.error("Error:", error);
      throw error;
    }
  }



  async markPoForModification(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.markPoForModification}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markPoForModification");
      console.error("Error:", error);
      throw error;
    }
  }

  async markPoForCancellation(poId: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.markPoForCancellation}${poId}`;
      const response = await this.api.put(url, {}, {
        withCredentials: true,
      });
      return response;

    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markPoForCancellation");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurchaseOrdersPaginated(
      searchKeyword: string,
      pageNumber: number,
      count: number
    ) {
      const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
      };
      try {
        let url = `${requestHeaders.rooturl}${requests.getPurchaseOrdersPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
          const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
          url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
      } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getPurchaseOrdersPaginated");
        console.error("Error:", error);
        throw error;
      }
  }

  async getPurcahseOrdersWithLotWiseListings(lastPoId: string,  count: string){
    const queryParams: Record<string, any> = {
      last_po_id: lastPoId,
      count: count
    };
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurcahseOrdersWithLotWiseListings}?count=${count}`;
      const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
      url += `&${queryString}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrdersWithLotWiseListings");
      console.error("Error:", error);
      throw error;
    }
  }

  async getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(
      searchKeyword: string,
      pageNumber: number,
      count: number){
    const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, //
    };
    try {
      let url = `${requestHeaders.rooturl}${requests.getPurcahseOrdersWithLotWiseListingsAndSearchKeyword}?search_keyword=${searchKeyword}`;
      const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
      url += `&${queryString}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getPurcahseOrdersWithLotWiseListings");
      console.error("Error:", error);
      throw error;
    }
  }

  
}

export default PurchaseOrderNetworkUtils;
