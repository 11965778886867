import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import { Vendor } from "../../atomic_components/types";
import "./CSS/VendorsAll.css";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import VendorsSearchBar from "./VendorsSearchBar";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const VendorsCard: React.FC<{
  vendor: Vendor;
  category: string;
  handleCardClick: (vendor: Vendor) => void;
}> = ({ vendor, category, handleCardClick }) => (
  <a href={`/vendors/${category}/${vendor.id}`}>
    <div className="vendors-view-card" onClick={() => handleCardClick(vendor)}>
      <Container>
        <Row>
          <Col sm={8}># {vendor.id}</Col>
          <Col sm={4} className="d-flex justify-content-end">
            {vendor.vendor_city}, {vendor.vendor_state}
          </Col>
        </Row>
      </Container>

      <div className="vendors-view-card-title">
        <h4 className="vendor_name">{vendor.vendor_name}</h4>
      </div>
      <div className="specialities-container">
        {vendor.specialties.map((sp, index) => (
          <Badge bg="success" key={index} className="speciality-badge">
            {sp.vendor_speciality}
          </Badge>
        ))}
      </div>
    </div>
  </a>
);

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function VendorsAll() {
  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [vendorsList, setVendorsList] = useState<Vendor[]>([]);
  const [filteredVendorsList, setFilteredVendorsList] = useState<Vendor[]>([]);

  const { category } = useParams<RouteParams>();
  const [showAddNewVendor, setShowAddNewVendor] = useState(false);
  const [newVendorName, setNewVendorName] = useState<string>("");

  const [newVendorAddressLine, setNewVendorAddressLine] = useState<string>("");
  const [newVendorCity, setNewVendorCity] = useState<string>("");
  const [newVendorState, setNewVendorState] = useState<string>("");
  const [newVendorPostalCode, setNewVendorPostalCode] = useState<string>("");
  const [newVendorGSTIN, setNewVendorGSTIN] = useState<string>("");
  const [newVendorContactNumber, setNewVendorContactNumber] =
    useState<string>("");
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const handleShowAddNewVendor = () => setShowAddNewVendor(true);
  const handleCloseAddNewVendor = () => {
    setShowAddNewVendor(false);
  };

  const handleCardClick = (vendor: Vendor) => {
    // Use pushState to add a new history state without navigating
    // window.history.pushState({ skuDetailsOpen: true }, ""); // The second parameter is the title (can be empty)
    // history.push(`/listings/${category}/${listing.sku_id}`);
    /*const url = `/vendors/${category}/${vendor.id}`;
    const win = window.open(url, "_blank");
    if (win) {
      win.focus(); // Focus on the new tab if it opened successfully
    } else {
      console.error("Popup blocked. Please allow popups for this website.");
    }*/
    // const url = `/vendors/${category}/${vendor.id}`;
    // history.push(url);
  };

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await vendorsNetworkUtil.getAllVendors();
        const vendors = response.data;
        console.log(vendors);
        setVendorsList(vendors);
        setFilteredVendorsList(vendors);
        // Do something with formCategories
      } catch (error) {
        console.log("Printing error from VendorsAll");
        console.error("Error:", error);
      }
    };
    fetchVendors();
  }, []);

  const handleSearch = (query: string) => {
    // dispatch(SearchText__Action(query));
    // search(query, 1);
    console.log("vendor searched ", query);
    if (query.length == 0) setFilteredVendorsList(_.cloneDeep(vendorsList));
    else setFilteredVendorsList(filterVendors(query));
  };

  const filterVendors = (query: string) => {
    query = query.toLowerCase();
    return vendorsList.filter((vendor) => {
      const vendorNameMatches = vendor.vendor_name
        .toLowerCase()
        .includes(query);
      const specialtyMatches = vendor.specialties.some((specialty) =>
        specialty.vendor_speciality.toLowerCase().includes(query)
      );
      const vendorCityMatches = vendor.vendor_city
        .toLowerCase()
        .includes(query);
      const vendorStateMatches = vendor.vendor_state
        .toLowerCase()
        .includes(query);
      const vendorAddressMatches = vendor.vendor_address_line
        .toLowerCase()
        .includes(query);
      const vendorPostalCodeMatches = vendor.vendor_postal_code
        .toLowerCase()
        .includes(query);
      const vendorIdMatches = vendor.id.toString().includes(query);
      return (
        vendorNameMatches ||
        specialtyMatches ||
        vendorIdMatches ||
        vendorCityMatches ||
        vendorStateMatches ||
        vendorAddressMatches ||
        vendorPostalCodeMatches
      );
    });
  };

  const openDrawerToAddNewVendor = () => {
    setShowAddNewVendor(true);
  };
  const handleNewVendorNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewVendorName(e.target.value);
  };

  const handleNewVendorAddressLineChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewVendorAddressLine(e.target.value);
  };

  const mapNewVendor = async () => {
    try {
      const newVendor: any = {
        vendor_name: newVendorName,
        vendor_address_line: newVendorAddressLine,
        vendor_city: newVendorCity,
        vendor_state: newVendorState,
        vendor_postal_code: newVendorPostalCode,
        vendor_gstin: newVendorGSTIN,
        vendor_contact_number: newVendorContactNumber,
        created_by: userDetailsFromStore.user_details.userId,
        modified_by: userDetailsFromStore.user_details.userId,
      };
      const response = await vendorsNetworkUtil.addNewVendor(newVendor);

      console.log(response);
      toast.success("Successfully added!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setFilteredVendorsList(_.cloneDeep([...vendorsList, response.data]));
      setVendorsList([...vendorsList, response.data]);

      setShowAddNewVendor(false);
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 400) {
        toast.error(
          "Error, the vendor name you are trying to add is already taken",
          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          }
        );
      } else {
        toast.error("Unnknown error.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <div className="vendors-container">
      <div className="vendor-search-bar-container">
        <div className="vendor-search-bar-container-child">
          <VendorsSearchBar onSearch={handleSearch} />
          <Button
            variant="outline-primary"
            className="button-add-new-vendor"
            onClick={openDrawerToAddNewVendor}
          >
            Add new vendor
          </Button>
        </div>
      </div>
      {filteredVendorsList.length != 0 ? (
        <div className="vendors-card-container-parent">
          <div className="vendors-card-container">
            {filteredVendorsList &&
              filteredVendorsList.length > 0 &&
              filteredVendorsList.map((vendor, index) => (
                <VendorsCard
                  key={index}
                  vendor={vendor}
                  category={category}
                  handleCardClick={handleCardClick}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className="no-items-view">Nothing to Display</div>
      )}

      <Offcanvas
        show={showAddNewVendor}
        onHide={handleCloseAddNewVendor}
        // backdrop="static"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <p>Add new vendor</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container">
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Vendor Name</label>
                <input
                  type="text"
                  name="new_vendor_name"
                  value={newVendorName}
                  onChange={(e) => handleNewVendorNameChange(e)}
                />
              </div>
            </div>
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Vendor Address Line</label>
                <input
                  type="text"
                  name="new_vendor_address_line"
                  value={newVendorAddressLine}
                  onChange={(e) => handleNewVendorAddressLineChange(e)}
                />
              </div>
            </div>
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Vendor City</label>
                <input
                  type="text"
                  name="new_vendor_city"
                  value={newVendorCity}
                  onChange={(e) => setNewVendorCity(e.target.value)}
                />
              </div>
            </div>
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Vendor State</label>
                <input
                  type="text"
                  name="new_vendor_state"
                  value={newVendorState}
                  onChange={(e) => setNewVendorState(e.target.value)}
                />
              </div>
            </div>
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Vendor Postal Code</label>
                <input
                  type="text"
                  name="new_vendor_postal_code"
                  value={newVendorPostalCode}
                  onChange={(e) => setNewVendorPostalCode(e.target.value)}
                />
              </div>
            </div>
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Vendor GSTIN</label>
                <input
                  type="text"
                  name="new_vendor_gstin"
                  value={newVendorGSTIN}
                  onChange={(e) => setNewVendorGSTIN(e.target.value)}
                />
              </div>
            </div>
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Vendor Contact Number</label>
                <input
                  type="text"
                  name="new_vendor_contact_number"
                  value={newVendorContactNumber}
                  onChange={(e) => setNewVendorContactNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="offcanvas-button-container">
              <button type="button" onClick={mapNewVendor}>
                Save
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default VendorsAll;
