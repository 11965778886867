import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./CSS/ListingBulkOperations.css";
import { useHistory, useLocation } from "react-router-dom";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { FilesStatus, ImsListing } from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { toast } from "react-toastify";
import jsonexport from "jsonexport";
import Papa from "papaparse";

const ListingsBulkOperations: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [show, setShow] = useState(true);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [filesHistory, setFilesHistory] = useState<FilesStatus[]>([]);
  const history = useHistory();
  const location = useLocation();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const [jsonData, setJsonData] = useState<any[] | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            // 'result' contains parsed CSV data
            // Convert CSV data to JSON
            // setJsonData(result.data);
            // jsonPayload = result.data;
            // console.log(jsonPayload);
            setJsonData(result.data);
            console.log(result.data);
          },
          header: true, // Treat the first row as header
        });
      }

      // console.log(jsonPayload);
      // setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    // Perform upload logic with the selected file
    // if (!file) {
    //   console.error("No file selected.");
    //   return;
    // }
    setDownloadButtonClicked(true);
    try {
      const response = await listingNetworkUtil.updateMultipleListings(
        jsonData
      );
      toast.success("Upload successful", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      // getFilesHistory();
      const fileInput = document.getElementById(
        "fileInput"
      ) as HTMLInputElement | null;
      if (fileInput) {
        fileInput.value = ""; // Clear the value
      }
      setFile(null);
      setDownloadButtonClicked(false);
      console.log(response);
    } catch (error) {
      console.log("Printing error from handleUpload updated inventory.");
      console.error("Error:", error);
      setDownloadButtonClicked(false);
      toast.error("There was an error uploading the file. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  // const getFilesHistory = async () => {
  //   try {
  //     const response = await listingNetworkUtil.getFilesHistory();
  //     setFilesHistory(response.data);
  //     console.log(response);
  //   } catch (error) {
  //     console.log(
  //       "Printing error from ListingBulkOperations download inventory snapshot"
  //     );
  //     console.error("Error:", error);
  //   }
  // };

  useEffect(() => {
    // getFilesHistory();
  }, []);

  const [imsListings, setImsListings] = useState<ImsListing[]>([]);

  const handleDownload = async () => {
    try {
      setDownloadButtonClicked(true);
      let total = 0,
        count = -1,
        pageNumber = 1;
      let allListings: ImsListing[] = [];
      while (count < total) {
        const response = await listingNetworkUtil.getListingsBySearchKeyword(
          "",
          pageNumber,
          200
        );
        if (count == -1) {
          count = response.data.curr_page_count;
          total = response.data.total;
        } else {
          count = count + response.data.curr_page_count;
        }
        pageNumber = pageNumber + 1;
        console.log(count, total, response.data.content);
        allListings.push(...response.data.content);
      }
      console.log(allListings);
      setDownloadButtonClicked(false);

      // const response = await listingNetworkUtil.getListingsBySearchKeyword(
      //   "",
      //   1,
      //   500
      // );

      // setDownloadButtonClicked(true);
      // getFilesHistory();
      jsonexport(allListings, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "data.csv";
        // Simulate a click to trigger the download
        link.click();
      });
      // console.log(response.data);
    } catch (error) {
      console.log(
        "Printing error from ListingBulkOperations download inventory snapshot"
      );
      console.error("Error:", error);
    }
  };

  const downloadLoadedFile = async (fileName: string) => {
    try {
      const response = await listingNetworkUtil.downloadFile(fileName);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName; // Specify the desired file name
      document.body.appendChild(link);

      // Trigger a click on the link to initiate download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
    } catch (error) {
      console.log(
        "Printing error from ListingBulkOperations download inventory snapshot"
      );
      console.error("Error:", error);
    }
  };

  return (
    <div className="bulk-operations-container pt-4 pl-4 pr-4 pb-4">
      <Container fluid className="mt-4 mb-4">
        <Row className="single-row pt-4 pl-4 pb-4">
          <Col>
            <div className="download-snapshot-container">
              <h6>Get latest listing Snapshot</h6>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={handleDownload}
              >
                Download
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="single-row pt-4 pl-4 pb-4 mt-4">
          <Col>
            <div className="upload-snapshot-container">
              <h6>Upload Updated Listings File</h6>
              <div className="upload-controls">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv"
                  id="fileInput"
                />
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    handleUpload();
                  }}
                >
                  Upload
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {downloadButtonClicked && (
        <div className="loading-page-horizontal">
          <Spinner animation="grow" className="mr-2" />
          <Spinner animation="grow" className="mr-2" />
          <Spinner animation="grow" className="mr-2" />
        </div>
      )}
    </div>
  );
};

export default ListingsBulkOperations;
