import React, { useEffect, useState } from "react";
import DynamicForm from "./DynamicForm";
import "./CSS/FormsPage.css";
import { useHistory, useParams } from "react-router-dom";
import FormsNetworkUtil from "./NetworkingUtils/FormsNetworkUtils";
import { Form, FormFields } from "../../atomic_components/types";
import DynamicForm2 from "./DynamicForm2";

// const formFields = [
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
//   {
//     type: "text",
//     label: "First Name",
//     name: "firstName",
//   },
//   {
//     type: "number",
//     label: "Age",
//     name: "age",
//   },
//   {
//     type: "text",
//     label: "Frames Manufactured",
//     name: "age",
//   },
//   {
//     type: "checkbox",
//     label: "Subscribe to Newsletter",
//     name: "subscribe",
//   },
// ];

interface RouteParams {
  category: string;
  subcategory: string; // Define the type for the parameter you're expecting
}

function FormsPage() {
  const { category, subcategory } = useParams<RouteParams>();
  const [form, setForm] = useState<Form>({
    id: 0,
    category: "",
    sub_category: "",
    form_name: "",
    form_payload: [],
    created_by: "",
    is_active: -1,
    version: 0,
    created_at: "",
    updated_at: "",
  });
  const history = useHistory();
  const formsNetworkUtil = new FormsNetworkUtil(
    "https://your-api-endpoint.com",
    history
  );
  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await formsNetworkUtil.getLatestFormBySubCategoryName(
          category,
          subcategory
        );
        const latestForm = response.data;
        console.log(latestForm);

        // Do something with formCategories
        setForm(latestForm);
      } catch (error) {
        console.log("Printing error from ListingsHome");
        console.error("Error:", error);
      }
    };

    fetchForm();
  }, []);
  return (
    <div className="forms-page">
      {/* <DynamicForm fields={formFields} /> */}
      <DynamicForm2 form={form} />
    </div>
  );
}

export default FormsPage;
