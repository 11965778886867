export const requestHeaders = {
  // rooturl : "https://data.intellozene.com/public/api/",

  // rooturl: "https://web.intellozene.com/public/api/",
  rooturl: "http://localhost:8000/api/",
  // rooturlIms: "http://localhost:9000/api/",
  rooturlIms: "https://test.intellozene.com/public/api/",
  rooturlAuthorization: "http://auth.intellozene.com/public/api/",
};

export const requests = {
  companiesList: "companies",
  addCompany: "companies",
  getCompanyById: "companies/",
  createNewGroup: "groups",
  createNewTeam: "teams",
  getAllGroups: "groups",
  getGroupByGroupId: "groups/",
  getAllTeamsOfaGroup: "groups/teams/",
  createPO: "po",
  getAllPos: "po",
  getAllUsersOfTeam: "teams/user/",
  AddUserToTeam: "teams/user",
  GetAllUsers: "users",
  GetUserById: "users/",
  AddSkusThroughExcel: "po/add_sku_sheet",
  GetTeambyId: "teams/",
  GetAllSkusPaginated: "po/listings/by_page/",
  GetAllLogsSku: "po/logs/",
  GetSkuFromSkuList: "sku_po/",
  GetPOSummary: "po/summary/",
  SubmitForm: "forms_response",
  Login: "login",
  AddNewSkuInPo: "po/add_sku",
  EditSkuQuantityPO: "editor/update/quantity/",
  ChangeSkuStatusPO: "editor/update/sku_status/",
  ListingsBySearchKeyword: "listings/by_page_v4",
  listingsBySkuId: "listings/sku/",
  topListings: "listings/topselling/500",
  updateListingBySkuId: "listings/sku/",
  updateListingInBulk: "listings/bulk",
  analyticsBySkuId: "listings/analytics/sku/",
  updateInventoryMulti: "warehouse_inventory/update_inventory_multi/",
  getAllFormCategories: "forms/categories",
  getAllFormCategoriesByName: "forms/categories/",
  getFormBySubCategoryName: "forms/categories/",
  saveFormResponse: "forms/response/",
  getFormsDataByFormIdFilledToday: "forms/today/",
  getAllVendors: "vendors/all",
  getVendorByVendorId: "vendors/",
  updateVendorByVendorId: "vendors/",
  updateVendorSpecialtiesByVendorId: "vendors/specialty/",
  getVendorListingsByVendorId: "vendors/listings/",
  updateCostPriceyByVendorIdAndSkuId: "vendors/cost_price/",
  getCostPriceTimeline: "vendors/cost_price_timeline/",
  getAllSkuIds: "listings/sku/names",
  mapNewItemToVendor: "vendors/cost_price/",
  getCurrentListingsSnapshot: "export-listings",
  getFilesHistory: "filesHistory",
  downloadFile: "files/download/",
  uploadListingsUpdateFile: "files/upload/listing-update",
  getPacksAndComboDetails: "packs_combos/sku/",
  updatePacksAndComboMappings: "packs_combos/sku/",
  mapNewItemToPack: "packs_combos/add_item/sku/",
  fetchAssociatedVendors: "vendors/sku/",
  addNewVendor: "vendors",
  createNewPurchaseOrder: "purchase_orders/add",
  getPurchaseOrderById: "purchase_orders/",
  addItemToPurchaseOrder: "purchase_orders/addItem/",
  getAddedItemsOfAPurchaseOrder: "purchase_orders/addedItems/",
  getPurchaseOrdersPaginated: "purchase_orders",
  publishPo: "purchase_orders/publish/",
  republishPo: "purchase_orders/republish/",
  markPoForModification: "purchase_orders/mark_for_modification/",
  markPoForCancellation: "purchase_orders/mark_for_cancellation/",
  getAddedItemsOfAPurchaseOrderWithListing:
    "purchase_orders/addedItems/withListing/",
  getCataloguesPaginated: "catalogues",
  getCatalogueItemms: "catalogues/allItems/",
  getCatalogueById: "catalogues/",
  getCatalogueThemes: "catalogues/themes",
  removeItemFromVendor: "vendors/remove-listing/",
  fetchIncomingInventoryBySkuId: "listings/incoming-quantity/",
  getPurcahseOrdersWithLotWiseListings: "purchase_orders/lot-listings",
  getPurcahseOrdersWithLotWiseListingsAndSearchKeyword:
    "purchase_orders/lot-listings/with-search-keyword",
  createNewInvoice: "invoices",
  getInvoiceByInvoiceId: "invoices/",
  addItemToInvoice: "invoices/addItem/",
  getAddedItemsOfAInvoice: "invoices/addedItems/",
  publishInvoice: "invoices/publish/",
  getAddedItemsOfAInvoiceWithListing: "invoices/addedItems/withListing/",
  getInvoicesPaginated: "invoices",
  deleteItemFromInvoice: "invoices/deleteItem/",
  deleteItemFromPurchaseOrer: "purchase_orders/deleteItem/",
};
