import { ActionType } from "../action-types/index";
import {ActionInterface} from "../actions-interface/index"

const initialState = -1 ;

const reducer = (state : number = initialState, action : ActionInterface)=>{

    switch(action.type){
        case ActionType.USER_ID:
            return action.payload;        
        default:
            return state
    }
}

export default reducer