import { ActionType, UserDetailsActionTypes } from "../action-types/index";
import { ActionInterface, UserDetailsAction } from "../actions-interface/index";
import { LoginDetails } from "../../atomic_components/types";

const initialState = {
  user_details: {
    id: 0,
    name: "",
    userId: "",
    email: "",
    email_verified_at: "",
    created_at: "",
    updated_at: "",
  },
  token: "",
};

const userDetailsReducer = (
  state: LoginDetails = initialState,
  action: UserDetailsAction
) => {
  switch (action.type) {
    case UserDetailsActionTypes.ADD_USER_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default userDetailsReducer;
