// PrivateRoute.tsx
import React from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
}

const cookieName: string = "jwt_token";
const checkCookieExpiration = () => {
  const cookieValue = Cookies.get(cookieName);
  // console.log(cookieValue);

  // if (cookieValue) {
  //   try {
  //     const cookieData = JSON.parse(cookieValue);

  //     if (cookieData && cookieData.expires) {
  //       const expirationDate = new Date(cookieData.expires);
  //       const currentDate = new Date();

  //       if (currentDate < expirationDate) {
  //         console.log(`Cookie "${cookieName}" is not expired.`);
  //       } else {
  //         console.log(`Cookie "${cookieName}" has expired.`);
  //       }
  //     } else {
  //       console.log(`Cookie "${cookieName}" does not have an expiration date.`);
  //     }
  //   } catch (error) {
  //     console.error(`Error parsing cookie "${cookieName}":`, error);
  //   }
  // } else {
  //   console.log(`Cookie "${cookieName}" does not exist.`);
  // }
};

const PrivateRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  // console.log("Printing :", isAuthenticated);
  const myPrivateAuthentication: boolean = true;
  checkCookieExpiration();
  return (
    <Route
      {...rest}
      render={(props) =>
        myPrivateAuthentication ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
export default PrivateRoute;
