import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";


class CatalogueNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }
  async getCataloguesPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.getCataloguesPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getCataloguesPaginated");
        console.error("Error:", error);
        throw error;
        }
    }

    async getCatalogueItems(catalogueId: string){
        try {
          let url = `${requestHeaders.rooturl}${requests.getCatalogueItemms}${catalogueId}`;
          const response = await this.api.get(url, {
            withCredentials: true,
          });
          return response;
    
        } catch (error) {
     
          console.log("Printing error from getCatalogueItems");
          console.error("Error:", error);
          throw error;
        }
    }

    async getCatalogueById(catalogueId: string){
        try {
          let url = `${requestHeaders.rooturl}${requests.getCatalogueById}${catalogueId}`;
          const response = await this.api.get(url, {
            withCredentials: true,
          });
          return response;
    
        } catch (error) {
     
          console.log("Printing error from getCatalogueItems");
          console.error("Error:", error);
          throw error;
        }
    }

    async getCatalogueThemes(){
      try {
        let url = `${requestHeaders.rooturl}${requests.getCatalogueThemes}`;
        const response = await this.api.get(url, {
          withCredentials: true,
        });
        return response;
  
      } catch (error) {
   
        console.log("Printing error from getCatalogueThemes");
        console.error("Error:", error);
        throw error;
      }
    }
}

export default CatalogueNetworkUtils;
