import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormsNetworkUtil from "./NetworkingUtils/FormsNetworkUtils";
import { useParams } from "react-router-dom";
import { SubForms } from "../../atomic_components/types";
import "./CSS/FormsCategory.css";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function FormsCategory() {
  const history = useHistory();
  const formsNetworkUtil = new FormsNetworkUtil(
    "https://your-api-endpoint.com",
    history
  );
  const [subCategories, setFormSubCategories] = useState<SubForms[]>([]);
  const { category } = useParams<RouteParams>();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await formsNetworkUtil.getAllFormSubCategories(
          category
        );
        const formCategories = response.data;
        console.log(formCategories);
        setFormSubCategories(formCategories);
        // Do something with formCategories
      } catch (error) {
        console.log("Printing error from ListingsHome");
        console.error("Error:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleClick = (subCategory: string) => {
    history.push(`/forms/${category}/${subCategory}`);
  };

  return (
    <div className="forms-home-container">
      <div className="forms-card-container">
        {subCategories.map((c) => (
          <div
            className="forms-card"
            key={c.sub_category}
            onClick={() => handleClick(c.sub_category)}
          >
            {c.form_name}

            {/* <Link
              to={`/forms/${category}/${c.sub_category}`}
            >
              {c.form_name}
            </Link> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FormsCategory;
