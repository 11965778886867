import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/InvoicesAll.css";
import PurchaseOrdersSearchBar from "./InvoicesSearchBar";
import {
  ImsInvoice,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { Prev } from "react-bootstrap/esm/PageItem";
import InvoiceNetworkUtils from "./NetworkUtils/InvoiceNetworkUtils";
import InvoicesSearchBar from "./InvoicesSearchBar";

function InvoicesAll() {
  const history = useHistory();
  const location = useLocation();
  const invoiceNetworkUtils = new InvoiceNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [allInvoices, setAllInvoices] = useState<ImsInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const getInvoicesPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response = await invoiceNetworkUtils.getInvoicesPaginated(
        fromHandleSearch == true ? searchString : searchParams.search_string,
        pageNumber,
        28
      );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllInvoices((prevList) => [...prevList, ...response.data["content"]]);

      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching vendor listings by vendor id :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllInvoices([]);
    setIsLoading(true);

    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getInvoicesPaginated(query, 1, true);
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getInvoicesPaginated("", searchParams.current_page_num + 1, false);
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  useEffect(() => {
    getInvoicesPaginated("", 1, false);
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const navigateToPurchaseOrderDetailsPage = (invoice: ImsInvoice) => {
    // let url = "";
    // if (invoice.status == "PENDING" || invoice.status == "MARKED_MODIFICATION") {
    //   url = `/purchase-orders/add/addItems/${invoice.po_id}`;
    // } else {
    //   url = `/purchase-orders/published/${invoice.po_id}`;
    // }
    // history.push(url);
    // const win = window.open(url, "_blank");
    // if (win) {
    //   win.focus(); // Focus on the new tab if it opened successfully
    // } else {
    //   console.error("Popup blocked. Please allow popups for this website.");
    // }
  };

  const getInvoiceStatus = (poStatus: string) => {
    if (poStatus == "PENDING") return "Pending";
    else if (poStatus == "PUBLISHED") return "Published";
    else if (poStatus == "MARKED_MODIFICATION")
      return "Marked For Modification";
    else if (poStatus == "MARKED_RECEIVING") return "Marked for Receiving";
    else if (poStatus == "MARKED_PARTIALLY_RECEIVED")
      return "Partially Received";
    else if (poStatus == "MARKED_COMPLETED") return "Completed";
    else if (poStatus == "MARKED_CANCELLED") return "Cancelled";
    return "NA";
  };

  return (
    <div className="purchase-orders-container">
      <div className="purchase-orders-search-bar-container">
        <div className="colors-overview-container">
          {/* <ColorCard color="red" />
          <ColorCard color="orange" /> */}
          {/* <ColorCard color="green" /> */}
        </div>
        <InvoicesSearchBar onSearch={handleSearch} />
      </div>
      {allInvoices.length > 0 ? (
        <div className="purchase-orders-table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Invoice Id</th>
                <th>Customer Name</th>
                <th>Customer Contact Number</th>
                <th>Invoice Status</th>
                <th>Item Count</th>
                <th>Total Quantity</th>
                <th>Invoice Date</th>
                <th>Date Invoice Published</th>
                <th>Last Updated</th>
                <th>Date Created</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {allInvoices.map((invoice, index) => {
                return (
                  <tr
                    className="purchase-orders-search-bar-row"
                    key={index}
                    onClick={() => navigateToPurchaseOrderDetailsPage(invoice)}
                  >
                    <td className="invoice-id-link">
                      <a
                        href={
                          invoice.invoice_status == "PENDING"
                            ? `/invoices/add/addItems/${invoice.id}`
                            : `/invoices/published/${invoice.id}`
                        }
                      >
                        {invoice.id}
                      </a>
                    </td>
                    <td>{invoice.customer_name}</td>
                    <td>{invoice.customer_contact_number}</td>
                    <td>{getInvoiceStatus(invoice.invoice_status)}</td>
                    <td>{invoice.item_count}</td>
                    <td>{invoice.total_quantity}</td>
                    <td>
                      {DateTimeUtils.formatDateTime(invoice.invoice_date)}
                    </td>
                    <td>
                      {invoice.invoice_status == "PENDING"
                        ? ""
                        : DateTimeUtils.formatDateTime(invoice.date_published)}
                    </td>
                    <td>{DateTimeUtils.formatDateTime(invoice.updated_at)}</td>
                    <td>{DateTimeUtils.formatDateTime(invoice.created_at)}</td>
                    <td>{invoice.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count && (
              <div className="load-more-purchase-orders-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-purchase-orders-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No Purchase Orders were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default InvoicesAll;
