import React, { useEffect, useState } from "react";
import "./CSS/ListingsHome.css";
import ListingsSearchBar from "./ListingsSearchBar";
import ListingsView from "./ListingsView";
import {
  ImsListing,
  Listing,
  ListingPage,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import axios, { AxiosError } from "axios";
import ListingNetworkUtil from "./NetworkUtils/ListingNetworkUtils";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import {
  ActiveTab__Action,
  Listings__Action,
  SearchText__Action,
  SetSearchParams__Action,
} from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import {
  ListingActionTypes,
  SearchTextActionTypes,
} from "../../state/action-types";
import { toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";

function ListingsHome() {
  const listingsFromStore = useAppSelector((state) => state.listings_store);
  const [listings, setListings] = useState<ImsListing[]>(listingsFromStore);
  const searchParams: SearchParams = useAppSelector(
    (state) => state.search_params
  );

  // console.log(searchParams);

  // useEffect(() => {
  //   const handlePageRefresh = (event: BeforeUnloadEvent) => {
  //     dispatch(Listings__Action(ListingActionTypes.RESET_LISTINGS, [], "", []));
  //   };
  //   window.addEventListener("beforeunload", handlePageRefresh);
  //   return () => {
  //     window.removeEventListener("beforeunload", handlePageRefresh);
  //   };
  // }, []);

  useEffect(() => {
    if (searchParams.item_count > 0) {
      refresh(searchParams.search_string, 1, searchParams.item_count);
    }
  }, []);

  useEffect(() => {
    setListings(listingsFromStore);
  }, [listingsFromStore]);

  const dispatch: AppDispatch = useDispatch();
  const [itemCount, setItemCount] = useState(searchParams.item_count);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);

  // const [expectedItemCount, setExpectedItemCount] = useState(
  //   searchParams.expected_item_count
  // );

  // const [currentpageNum, setcurrentpageNum] = useState(
  //   searchParams.current_page_num
  // );

  const history = useHistory();
  const location = useLocation();
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const refresh = async (query: string, pageNumber: number, count: number) => {
    try {
      const response = await listingNetworkUtil.getListingsBySearchKeyword(
        query,
        pageNumber,
        count
      );
      setItemCount(response.data["curr_page_count"]);
      // setExpectedItemCount(response.data["total"]);
      // setcurrentpageNum(currentpageNum);
      const newListings: Array<ImsListing> = response.data["content"];
      // console.log(newListings);
      setListings(newListings);
      dispatch(
        Listings__Action(
          ListingActionTypes.REPLACE_LISTINGS,
          newListings,
          "",
          []
        )
      );

      dispatch(
        SetSearchParams__Action(SearchTextActionTypes.SET_SEARCH_PARAMS, {
          search_string: query,
          item_count: response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: searchParams.current_page_num,
        })
      );
    } catch (error) {
      console.log("Printing error from ListingsHome refresh");
      console.error("Error:", error);
    }
  };

  const search = async (query: string, pageNumber: number, count?: number) => {
    try {
      const response = await listingNetworkUtil.getListingsBySearchKeyword(
        query,
        pageNumber,
        count ? count : 35
      );

      if (pageNumber == 1) setItemCount(response.data["curr_page_count"]);
      else setItemCount(itemCount + response.data["curr_page_count"]);
      // setExpectedItemCount(response.data["total"]);
      // if (pageNumber) setcurrentpageNum(pageNumber + 1);
      // else setcurrentpageNum(currentpageNum + 1);
      const newListings: Array<ImsListing> = response.data["content"];
      setListings((prevList) => [...prevList, ...newListings]);
      dispatch(
        Listings__Action(ListingActionTypes.ADD_LISTINGS, newListings, "", [])
      );

      dispatch(
        SetSearchParams__Action(SearchTextActionTypes.SET_SEARCH_PARAMS, {
          search_string: query,
          item_count:
            pageNumber == 1
              ? response.data["curr_page_count"]
              : itemCount + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: pageNumber + 1,
        })
      );
      setShowLoadingPage(false);
    } catch (error) {
      // if(axios.isAxiosError(error)){
      //   if (error.response && error.response.status === 401) {
      //     console.log('Unauthorized request in catch block. Redirecting to login page.');
      //     history.push('/login');
      //   }
      // }
      console.log("Printing error from ListingsHome");
      console.error("Error:", error);
      setShowLoadingPage(false);
    }
  };
  const handleSearch = (query: string) => {
    // dispatch(SearchText__Action(query));
    dispatch(
      SetSearchParams__Action(SearchTextActionTypes.SET_SEARCH_PARAMS, {
        search_string: query,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
      })
    );
    setShowLoadingPage(true);
    dispatch(Listings__Action(ListingActionTypes.RESET_LISTINGS, [], "", []));
    setListings([]);
    setItemCount(0);
    search(query, 1);
  };

  return (
    <div className="PageContainer">
      <div className="listings-search-bar-container">
        <div className="listings-search-bar-container-child">
          <ListingsSearchBar onSearch={handleSearch} />
        </div>
      </div>
      {listings.length != 0 && (
        <div className="all-listings-container">
          <ListingsView listings={listings} />
          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count && (
              <div>
                <Button
                  variant="outline-primary"
                  className="next-button"
                  onClick={() =>
                    search(
                      searchParams.search_string,
                      searchParams.current_page_num
                    )
                  }
                >
                  Load More..
                </Button>
              </div>
            )}
        </div>
      )}
      {listings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Searching, please wait...
        </div>
      )}
    </div>
  );
}

export default ListingsHome;
