import React, { useState, useEffect } from "react";
import "./CSS/InvoicesEmbeddedListingsView.css";
import {
  CostPriceTimelineItem,
  ImsInvoiceItem,
  Listing,
  ListingPage,
  PurchaseOrderItemWithLisiting,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

const ListingCard: React.FC<{
  invoiceListing: ImsInvoiceItem;
  handleCardClick: (listing: ImsInvoiceItem) => void;
}> = ({ invoiceListing, handleCardClick }) => (
  <div
    className="listings-view-card vendor-listing-card invoice-item-card"
    onClick={() => handleCardClick(invoiceListing)}
  >
    <div className="listings-img-container">
      <img src={invoiceListing.listing.img_main} alt="Product Image" />
    </div>

    <div className="listings-view-card-title">
      <h3
        className={`sku-id-title ${
          invoiceListing.listing.available_quantity === 0
            ? "not-available"
            : "available"
        }`}
      >
        {invoiceListing.listing.item_id}
      </h3>
    </div>

    <p>Available Quantity: {invoiceListing.listing.available_quantity}</p>
    <p>Current Price : {invoiceListing.listing.item_price}</p>

    {/* <p>Available Quantity: {invoiceListing.listing.available_quantity}</p> */}

    <div className="required-quantity-container">
      <p>Invoice Quantity : </p>
      <input
        type="text"
        name="vendor_cost_price"
        value={invoiceListing.item_quantity}
        //   onChange={handleChange}
        //   disabled={!editMode}
      />
    </div>

    <div className="invoice-price-container mt-2">
      <div className="invoice-price-items-container">
        <p>Actual Price : </p>
        <input
          type="text"
          name="vendor_cost_price"
          value={invoiceListing.item_actual_price}
          //   onChange={handleChange}
          //   disabled={!editMode}
        />
      </div>
      <div className="invoice-price-items-container">
        <p>Final Price : </p>
        <input
          type="text"
          name="vendor_cost_price"
          value={invoiceListing.item_final_price}
          //   onChange={handleChange}
          //   disabled={!editMode}
        />
      </div>
    </div>
    <p className="last-modified">
      Last Modified: {DateTimeUtils.formatDateTime(invoiceListing.updated_at)}
    </p>
  </div>
);

type ListingsViewProps = {
  publishedListings: ImsInvoiceItem[];
  onCardClick: (selectedListing: ImsInvoiceItem) => void;
};

export default function InvoicesPublishedItemsView({
  publishedListings,
  onCardClick,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const handleCardClick = async (listing: ImsInvoiceItem) => {
    onCardClick(listing);
  };

  return (
    <div>
      {publishedListings && publishedListings.length > 0 && (
        <div className="vendor-listings-card-container">
          {publishedListings.map((publishedListing, index) => (
            <ListingCard
              key={index}
              invoiceListing={publishedListing}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}

      {publishedListings && publishedListings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
